/*
@File: COVID19 Template Styles

* This file contains the styling for the actual template, this
is the file you need to edit to change the look of the
template.

This files table contents are outlined below>>>>>

*******************************************
*******************************************

** - Default CSS
** - Preloader Area CSS
** - Header Area CSS
** - Search Overlay CSS
** - Main Banner Area CSS
** - About Area CSS
** - CTA Area CSS
** - Spreads Virus Area CSS
** - Symptoms Area CSS
** - Funfacts Area CSS
** - How To Protect Area CSS
** - FAQ Area CSS
** - Appointment Area CSS
** - Doctors Area CSS
** - Emergency Contact Area CSS
** - Products Area CSS
** - Products Details Area CSS
** - Cart Area CSS
** - Checkout Area CSS
** - Blog Area CSS
** - Blog Details Area CSS
** - Subscribe Area CSS
** - Page Title Area CSS
** - Pagination CSS
** - Widget Sidebar CSS
** - Coming Soon Area CSS
** - Error 404 Area CSS
** - Contact Area CSS
** - Footer Area CSS
** - Go Top CSS
*/

/*================================================
Default CSS
=================================================*/
$font-family: 'Open Sans', sans-serif;
$font-size: 15px;
$main-color: #4f87fb;
$black-color: #130947;
$white-color: #ffffff;
$optional-color: #606060;
$transition: .5s;

body {
    padding: 0;
    margin: 0;
    font: {
        family: $font-family;
        size: $font-size;
    };
}
:focus {
    outline: 0 !important;
}
a {
    text-decoration: none;
    color: $black-color;
    transition: $transition;

    &:hover {
        text-decoration: none;
        color: $main-color;
    }
}
img {
    height: auto;
    max-width: 100%;
}
.d-table {
    width: 100%;
    height: 100%;

    &-cell {
        vertical-align: middle;
    }
}
p {
    margin-bottom: 15px;
    line-height: 1.8;
    color: $optional-color;

    &:last-child {
        margin-bottom: 0;
    }
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-family: $font-family;
    color: $black-color;
}
.bg-f9f9f9 {
    background-color: #f9f9f9;
}
.ptb-100 {
    padding: {
        top: 100px;
        bottom: 100px;
    };
}
.pt-100 {
    padding-top: 100px;
}
.pb-100 {
    padding-bottom: 100px;
}
.ptb-70 {
    padding: {
        top: 70px;
        bottom: 70px;
    };
}
.pt-70 {
    padding-top: 70px;
}
.pb-70 {
    padding-bottom: 70px;
}
.container {
    max-width: 1230px;
}
/*default&optional-btn*/
.default-btn {
    position: relative;
    z-index: 1;
    text-transform: capitalize;
    background-color: $main-color;
    color: $white-color;
    border: 1px solid $main-color;
    display: inline-block;
    transition: $transition;
    padding: 12px 25px 12px 50px;
    border-radius: 5px;
    font: {
        size: 14px;
        weight: 700;
    };
    i {
        position: absolute;
        left: 25px;
        top: 49%;
        transform: translateY(-49%);
        font-size: 15px;
    }
    &:hover {
        color: $white-color;
        border-color: $black-color;
        background-color: $black-color;
    }
}
.optional-btn {
    position: relative;
    z-index: 1;
    text-transform: capitalize;
    background-color: $black-color;
    color: $white-color;
    display: inline-block;
    transition: $transition;
    padding: 11.9px 25px 11.9px 50px;
    border-radius: 5px;
    border: 1px solid $black-color;
    font: {
        size: 14px;
        weight: 700;
    };
    i {
        position: absolute;
        left: 25px;
        top: 48%;
        transform: translateY(-48%);
        font-size: 15px;
    }
    &:hover {
        color: $white-color;
        background-color: $main-color;
        border-color: $main-color;

        &::before {
            border-color: $main-color;
        }
    }
}
/*section-title*/
.section-title {
    text-align: center;
    margin-bottom: 60px;

    .sub-title {
        text-transform: uppercase;
        margin-bottom: 10px;
        color: $main-color;
        display: block;
        font: {
            size: 14px;
            weight: 600;
        };
    }
    h2 {
        margin-bottom: 0;
        font: {
            size: 35px;
            weight: 800;
        };
    }
}
/*form-control*/
.form-control {
    background-color: #f5f5f5;
    height: 48px;
    border-radius: 0;
    box-shadow: unset !important;
    padding: 0 0 0 15px;
    border: 1px solid #f5f5f5;
    color: $black-color;
    font-size: $font-size;
    transition: $transition;

    &:focus {
        border-color: $main-color;
        background-color: $white-color;

        &::placeholder {
            color: transparent;
        }
    }
    &::placeholder {
        transition: $transition;
    }
}
textarea.form-control {
    padding-top: 15px;
    height: auto !important;
}

/*================================================
Preloader Area CSS
=================================================*/
.preloader {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background: $main-color;
    top: 0;
    left: 0;

    .loader {
        position: absolute;
        top: 43%;
        left: 0;
        right: 0;
        transform: translateY(-43%);
        text-align: center;
        margin: 0 auto;
        width: 50px;
        height: 50px;
    }
    .box {
        width: 100%;
        height: 100%;
        background: $white-color;
        animation: animate .5s linear infinite;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 3px;
    }
    .shadow { 
        width: 100%;
        height: 5px;
        background: #000;
        opacity: 0.1;
        position: absolute;
        top: 59px;
        left: 0;
        border-radius: 50%;
        animation: shadow .5s linear infinite;
    }
}
@keyframes loader {
    0% {
        left: -100px
    }
    100% {
        left: 110%;
    }
}
@keyframes animate {
    17% {
        border-bottom-right-radius: 3px;
    }
    25% {
        transform: translateY(9px) rotate(22.5deg);
    }
    50% {
        transform: translateY(18px) scale(1,.9) rotate(45deg);
        border-bottom-right-radius: 40px;
    }
    75% {
        transform: translateY(9px) rotate(67.5deg);
    }
    100% {
        transform: translateY(0) rotate(90deg);
    }
}
@keyframes shadow {
    50% {
        transform: scale(1.2,1);
    }
}

/*================================================
Header Area CSS
=================================================*/
.top-header {
    background-color: #f5f8ff;
    padding: {
        top: 10px;
        bottom: 10px;
    };
}
.top-header-social {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;

    li {
        display: inline-block;
        margin-right: 7px;

        a {
            display: block;
            color: $main-color;
            font-size: 20px;

            &:hover {
                color: $black-color;
                transform: translateY(-2px);
            }
        }
        &:last-child {
            margin-right: 0;
        }
    }
}
.top-header-contact-info {
    padding-left: 0;
    margin-bottom: 0;
    text-align: end;
    list-style-type: none;

    li {
        display: inline-block;
        margin-right: 12px;
        color: $black-color;
        font-size: 14px;
        position: relative;
        padding-left: 24px;

        i {
            position: absolute;
            left: 0;
            top: 1px;
            color: $main-color;
            font-size: 18px;
        }
        a {
            display: inline-block;
            font-weight: 600;
        }
        &:first-child {
            margin-left: 0;
        }
    }
}
.navbar-area {
    transition: $transition;

    &.is-sticky {
        opacity: 1;
        visibility: visible;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 999;
        box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
        background: $white-color !important;
        animation: .5s ease-in-out 0s normal none 1 running fadeInDown;
    }
    &.navbar-style-two {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 999;
        height: auto;
        background-color: transparent;

        .qovid-nav {
            background-color: transparent;

            .navbar {
                .navbar-nav {
                    .nav-item {        
                        a {
                            color: $white-color;

                            &:hover, &:focus, &.active {
                                color: $white-color;
                            }
                        }
                        &:hover, &.active {
                            a {
                                color: $white-color;
                            }
                        }
                        .dropdown-menu {
                            li {
                                a {
                                    color: $black-color;
                                    
                                    &:hover, &:focus, &.active {
                                        color: $main-color;
                                    }
                                }
                                .dropdown-menu {
                                    li {
                                        a {
                                            color: $black-color;
                                            
                                            &:hover, &:focus, &.active {
                                                color: $main-color;
                                            }
                                        }
                                        .dropdown-menu {
                                            li {
                                                a {
                                                    color: $black-color;
                                                    
                                                    &:hover, &:focus, &.active {
                                                        color: $main-color;
                                                    }
                                                }
                                                .dropdown-menu {
                                                    li {
                                                        a {
                                                            color: $black-color;
                                                            
                                                            &:hover, &:focus, &.active {
                                                                color: $main-color;
                                                            }
                                                        }
                                                        .dropdown-menu {
                                                            li {
                                                                a {
                                                                    color: $black-color;
                                                                    
                                                                    &:hover, &:focus, &.active {
                                                                        color: $main-color;
                                                                    }
                                                                }
                                                                .dropdown-menu {
                                                                    li {
                                                                        a {
                                                                            color: $black-color;
                                                                            
                                                                            &:hover, &:focus, &.active {
                                                                                color: $main-color;
                                                                            }
                                                                        }
                                                                        .dropdown-menu {
                                                                            left: 250px;
                                                                            top: 0;
                                                                            opacity: 0;
                                                                            visibility: hidden;
                                                                            transform: scaleY(0);
                                                
                                                                            li {
                                                                                a {
                                                                                    color: $black-color;
                                                                                    
                                                                                    &:hover, &:focus, &.active {
                                                                                        color: $main-color;
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                        &.active {
                                                                            a {
                                                                                color: $main-color;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                &.active {
                                                                    a {
                                                                        color: $main-color;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        &.active {
                                                            a {
                                                                color: $main-color;
                                                            }
                                                        }
                                                    }
                                                }
                                                &.active {
                                                    a {
                                                        color: $main-color;
                                                    }
                                                }
                                            }
                                        }
                                        &.active {
                                            a {
                                                color: $main-color;
                                            }
                                        }
                                    }
                                }
                                &.active {
                                    a {
                                        color: $main-color;
                                    }
                                }
                            }
                        }
                    }
                }
                .others-option {
                    padding-left: 0;
                    margin-left: 24px;

                    &::before {
                        display: none;
                    }
                }
            }
        }
        .container-fluid {
            padding: {
                left: 50px;
                right: 50px;
            };
        }
        &.is-sticky {
            opacity: 1;
            visibility: visible;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 999;
            box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
            background: $black-color !important;
            animation: .5s ease-in-out 0s normal none 1 running fadeInDown;

            .qovid-nav {
                .navbar {
                    .others-option {
                        .option-item {
                            .default-btn {
                                border-color: $white-color;
                                background-color: $white-color;
                                color: $black-color;
            
                                &:hover {
                                    background-color: $main-color;
                                    color: $white-color;
                                    border-color: $main-color;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.navbar-style-three {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 999;
        height: auto;
        background-color: transparent;

        .qovid-nav {
            background-color: transparent;

            .navbar {
                .others-option {
                    padding-left: 0;
                    margin-left: 24px;

                    &::before {
                        display: none;
                    }
                    .option-item {
                        .default-btn {
                            background-color: #ac0001;
                            border-color: #ac0001;
                            color: $white-color;
                            border-radius: 30px;
                            padding: {
                                top: 11px;
                                bottom: 11px;
                            };
                            &:hover {
                                color: $white-color;
                                border-color: $main-color;
                                background-color: $main-color;
                            }
                        }
                    }
                }
            }
        }
        .container-fluid {
            padding: {
                left: 60px;
                right: 60px;
            };
        }
        &.is-sticky {
            opacity: 1;
            visibility: visible;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 999;
            box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
            background: $white-color !important;
            animation: .5s ease-in-out 0s normal none 1 running fadeInDown;
        }
    }
}
.qovid-responsive-nav {
    display: none;
}
.qovid-nav {
    background-color: $white-color;
    padding: {
        top: 10px;
        bottom: 10px;
    };
    .navbar {
        padding: {
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        };      
        .navbar-brand {
            font-size: inherit;
            line-height: 1;
            padding: {
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            };
        }
        ul {
            padding-left: 0;
            list-style-type: none;
            margin-bottom: 0;
        }
        .navbar-nav {
            margin: {
                left: auto;
            };
            .nav-item {
                position: relative;

                a {
                    color: $black-color;
                    text-transform: uppercase;
                    font: {
                        size: 14px;
                        weight: 700;
                    };
                    padding: {
                        left: 0;
                        right: 0;
                        top: 20px;
                        bottom: 20px;
                    };
                    margin: {
                        left: 12px;
                        right: 12px;
                    };
                    i {
                        line-height: 1;
                        font-size: 18px;
                        position: relative;
                        top: 2px;
                        display: inline-block;
                        margin: {
                            left: -3px;
                            right: -3px;
                        };
                    }
                    &:hover, &:focus, &.active {
                        color: $main-color;
                    }
                }
                &:last-child {
                    a {
                        margin-right: 0;
                    }
                }
                &:first-child {
                    a {
                        margin-left: 0;
                    }
                }
                &:hover, &.active {
                    a {
                        color: $main-color;
                    }
                }
                .dropdown-menu {
                    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
                    background: $white-color;
                    position: absolute;
                    border: none;
                    top: 70px;
                    left: 0;
                    width: 250px;
                    z-index: 99;
                    display: block;
                    opacity: 0;
                    visibility: hidden;
                    border-radius: 0;
                    margin: 0;
                    transition: all 0.2s ease-in-out;
                    padding: 0;
                    transform: scaleX(0);

                    li {
                        a {
                            padding: 13px 20px 11px;
                            margin: 0;
                            position: relative;
                            display: block;
                            color: $black-color;
                            border-bottom: 1px dashed #e5e5e5;
                            text-transform: capitalize;
                            font: {
                                size: 15px;
                                weight: 600;
                            };
                            i {
                                margin: 0;
                                position: absolute;
                                top: 50%;
                                font-size: 20px;
                                transform: translateY(-50%);
                                right: 10px;
                            }
                            &:hover, &:focus, &.active {
                                color: $main-color;
                            }
                        }
                        .dropdown-menu {
                            left: -250px;
                            top: 0;
                            opacity: 0;
                            visibility: hidden;
                            transform: scaleY(0);
                            
                            li {
                                a {
                                    color: $black-color;
                                    
                                    &:hover, &:focus, &.active {
                                        color: $main-color;
                                    }
                                }
                                .dropdown-menu {
                                    left: 250px;
                                    top: 0;
                                    opacity: 0;
                                    visibility: hidden;
                                    transform: scaleY(0);
        
                                    li {
                                        a {
                                            color: $black-color;
        
                                            &:hover, &:focus, &.active {
                                                color: $main-color;
                                            }
                                        }
                                        .dropdown-menu {
                                            left: -250px;
                                            top: 0;
                                            opacity: 0;
                                            visibility: hidden;
                                            transform: scaleY(0);
                
                                            li {
                                                a {
                                                    color: $black-color;
                
                                                    &:hover, &:focus, &.active {
                                                        color: $main-color;
                                                    }
                                                }
                                                .dropdown-menu {
                                                    left: 250px;
                                                    top: 0;
                                                    opacity: 0;
                                                    visibility: hidden;
                                                    transform: scaleY(0);
                        
                                                    li {
                                                        a {
                                                            color: $black-color;
                
                                                            &:hover, &:focus, &.active {
                                                                color: $main-color;
                                                            }
                                                        }
                                                        .dropdown-menu {
                                                            left: -250px;
                                                            top: 0;
                                                            opacity: 0;
                                                            visibility: hidden;
                                                            transform: scaleY(0);
                                
                                                            li {
                                                                a {
                                                                    color: $black-color;
                
                                                                    &:hover, &:focus, &.active {
                                                                        color: $main-color;
                                                                    }
                                                                }
                                                                .dropdown-menu {
                                                                    left: 250px;
                                                                    top: 0;
                                                                    opacity: 0;
                                                                    visibility: hidden;
                                                                    transform: scaleY(0);
                                        
                                                                    li {
                                                                        a {
                                                                            color: $black-color;
                
                                                                            &:hover, &:focus, &.active {
                                                                                color: $main-color;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                &.active {
                                                                    a {
                                                                        color: $main-color;
                                                                    }
                                                                }
                                                                &:hover {
                                                                    .dropdown-menu {
                                                                        opacity: 1;
                                                                        visibility: visible;
                                                                        transform: scaleY(1);
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        &.active {
                                                            a {
                                                                color: $main-color;
                                                            }
                                                        }
                                                        &:hover {
                                                            .dropdown-menu {
                                                                opacity: 1;
                                                                visibility: visible;
                                                                transform: scaleY(1);
                                                            }
                                                        }
                                                    }
                                                }
                                                &.active {
                                                    a {
                                                        color: $main-color;
                                                    }
                                                }
                                                &:hover {
                                                    .dropdown-menu {
                                                        opacity: 1;
                                                        visibility: visible;
                                                        transform: scaleY(1);
                                                    }
                                                }
                                            }
                                        }
                                        &.active {
                                            a {
                                                color: $main-color;
                                            }
                                        }
                                        &:hover {
                                            .dropdown-menu {
                                                opacity: 1;
                                                visibility: visible;
                                                transform: scaleY(1);
                                            }
                                        }
                                    }
                                }
                                &.active {
                                    a {
                                        color: $main-color;
                                    }
                                }
                                &:hover {
                                    .dropdown-menu {
                                        opacity: 1;
                                        visibility: visible;
                                        transform: scaleY(1);
                                    }
                                }
                            }
                        }
                        &.active {
                            a {
                                color: $main-color;
                            }
                        }
                        &:hover {
                            .dropdown-menu {
                                opacity: 1;
                                visibility: visible;
                                transform: scaleY(1);
                            }
                        }
                    }
                }
                &:hover {
                    .dropdown-menu {
                        opacity: 1;
                        visibility: visible;
                        transform: scaleX(1);
                    }
                }
            }
        }
        .others-option {
            position: relative;
            margin-left: 18px;
            padding-left: 18px;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 12px;
                width: 2px;
                height: 25px;
                background-color: #bdbbcc;
            }
            .option-item {
                display: inline-block;
                margin-left: 20px;
                position: relative;
                z-index: 3;

                &:first-child {
                    margin-left: 0;
                }
                .search-btn-box {
                    position: relative;
                    top: 2px;
                    
                    .search-btn {
                        font-size: 25px;
                        cursor: pointer;
                        color: $black-color;
                        transition: $transition;

                        &:hover {
                            color: $main-color;
                        }
                    }
                }
                .cart-btn {
                    font-size: 25px;
                    position: relative;
                    line-height: 1;

                    a {
                        display: inline-block;
                        position: relative;
                        color: $black-color;

                        span {
                            position: absolute;
                            right: -4px;
                            top: -4px;
                            width: 14px;
                            height: 14px;
                            text-align: center;
                            line-height: 13.9px;
                            border-radius: 50%;
                            color: $white-color;
                            background-color:$main-color;
                            font: {
                                size: 10px;
                                weight: 600;
                            };
                        }
                        &:hover {
                            color: $main-color;
                        }
                    }
                }
                .default-btn {
                    border-color: $black-color;
                    background-color: $black-color;
                    color: $white-color;

                    &:hover {
                        background-color: $main-color;
                        color: $white-color;
                        border-color: $main-color;
                    }
                }
            }
        }
    }
}
.onepage-navbar {
    &.navbar {
        background-color: $white-color;
        padding: {
            left: 0;
            right: 0;
            top: 10px;
            bottom: 10px;
        };
    }
    &.navbar-light {
        .navbar-brand {
            font-size: inherit;
            line-height: 1;
            padding: {
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            };
        }
        ul {
            padding-left: 0;
            list-style-type: none;
            margin-bottom: 0;
        }
        .navbar-nav {
           margin-left: auto;

            .nav-item {    
                a {
                    color: $black-color;
                    text-transform: uppercase;
                    font: {
                        size: 14px;
                        weight: 700;
                    };
                    padding: {
                        left: 0;
                        right: 0;
                        top: 20px;
                        bottom: 20px;
                    };
                    margin: {
                        left: 12px;
                        right: 12px;
                    };
                    &:hover, &:focus, &.active {
                        color: $main-color;
                    }
                }
                &:last-child {
                    a {
                        margin-right: 0;
                    }
                }
                &:first-child {
                    a {
                        margin-left: 0;
                    }
                }
                &:hover, &.active {
                    a {
                        color: $main-color;
                    }
                }
            }
        }
    }
    &.is-sticky {
        opacity: 1;
        visibility: visible;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 999;
        box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
        background: $white-color !important;
        animation: .5s ease-in-out 0s normal none 1 running fadeInDown;
    }
}

// Mobile Navbar
@media only screen and (max-width: 767px) {

    .navbar-area {
        padding: {
            top: 15px;
            bottom: 15px;
        };
        &.navbar-style-two {
            .container-fluid {
                padding: {
                    left: 15px;
                    right: 15px;
                };
            }
            .qovid-nav {
                .navbar-light {
                    .navbar-toggler {
                        border-color: $white-color;
                    }
                    .navbar-toggler-icon {
                        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
                    }
                }
                .navbar {
                    .others-option {
                        margin-left: 0;
                        padding-left: 15px;
                    }
                    .navbar-nav {
                        .nav-item {
                            a {
                                color: $black-color;
                            }
                        }
                    }
                }
            }
        }
        &.navbar-style-three {
            .container-fluid {
                padding: {
                    left: 15px;
                    right: 15px;
                };
            }
        }
    }
    .qovid-nav {
        padding: {
            top: 0;
            bottom: 0;
        };
        .navbar-light {
            .navbar-toggler {
                border-color: $black-color;
                border-radius: 0;
            }
            .navbar-toggler-icon {
                background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
            }
        }
        .navbar {
            .navbar-nav {
                border: 1px solid #eeeeee;
                padding: 10px 20px;
                height: 350px;
                background-color: $white-color;
                overflow-y: scroll;
                margin: {
                    left: 0;
                    top: 15px;
                };
                .nav-item {
                    a {
                        padding: {
                            top: 10px;
                            bottom: 10px;
                        };
                        margin: {
                            left: 0;
                            right: 0;
                        };
                    }
                    .dropdown-menu {
                        box-shadow: unset;
                        background: transparent;
                        position: relative;
                        top: 0;
                        left: 0;
                        width: 100%;
                        opacity: 1;
                        visibility: visible;
                        transform: unset;

                        li {
                            a {
                                padding: 8px 15px;
                                border-bottom: none;
                                font-size: 14.5px;

                                i {
                                    transform: rotate(90deg);
                                }
                            }
                            .dropdown-menu {
                                box-shadow: unset;
                                background: transparent;
                                position: relative;
                                top: 0;
                                left: 0;
                                width: 100%;
                                opacity: 1;
                                visibility: visible;
                                transform: unset;
                                padding: 0 18px;

                                li {
                                    .dropdown-menu {
                                        box-shadow: unset;
                                        background: transparent;
                                        position: relative;
                                        top: 0;
                                        left: 0;
                                        width: 100%;
                                        opacity: 1;
                                        visibility: visible;
                                        transform: unset;
                                        padding: 0 18px;

                                        li {
                                            .dropdown-menu {
                                                box-shadow: unset;
                                                background: transparent;
                                                position: relative;
                                                top: 0;
                                                left: 0;
                                                width: 100%;
                                                opacity: 1;
                                                visibility: visible;
                                                transform: unset;
                                                padding: 0 18px;

                                                li {
                                                    .dropdown-menu {
                                                        box-shadow: unset;
                                                        background: transparent;
                                                        position: relative;
                                                        top: 0;
                                                        left: 0;
                                                        width: 100%;
                                                        opacity: 1;
                                                        visibility: visible;
                                                        transform: unset;
                                                        padding: 0 18px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .others-option {
                background-color: $white-color;
                position: relative;
                margin-left: 0;
                border: 1px solid #eeeeee;
                border-top: none;
                padding: 15px 20px;

                &::before {
                    display: none;
                }
                .search-btn-box {
                    display: none;
                }
                .option-item {
                    margin-left: 15px;
                }
            }
        }
    }
    .onepage-navbar {
        &.navbar {
            border-bottom: 1px solid #eeeeee;
            padding: {
                top: 15px;
                bottom: 15px;
            };
            .container {
                padding: {
                    left: 15px;
                    right: 15px;
                };
            }
            &.is-sticky {
                border-bottom: none;
            }
        }
        &.navbar-light {
            .navbar-toggler {
                color: $black-color;
                border: none;
                padding: 0;
    
                .burger-menu {
                    cursor: pointer;
                    transition: $transition;
        
                    span {
                        height: 2px;
                        width: 30px;
                        background: $black-color;
                        display: block;
                        margin: 6px 0;
                        transition: all .50s ease-in-out;
                    }
                    &.active {
                        span {
                            &.top-bar {
                                transform: rotate(45deg);
                                transform-origin: 10% 10%;
                            }
                            &.middle-bar {
                                opacity: 0;
                            }
                            &.bottom-bar {
                                transform: rotate(-45deg);
                                transform-origin: 10% 90%;
                                margin-top: 5px;
                            }
                        }
                    }
                }
            }
            .navbar-nav {
                background-color: $white-color;
                border: 1px solid #eeeeee;
                margin-top: 15px;
                padding: {
                    left: 15px;
                    right: 15px;
                    top: 8px;
                    bottom: 8px;
                };
                .nav-item {
                    .nav-link {
                        padding: {
                            left: 0;
                            right: 0;
                            top: 8px;
                            bottom: 8px;
                        };
                        margin: {
                            left: 0;
                            right: 0;
                        };
                    }
                }
            }
        }
    }

}
// iPad Navbar for OnePage Home
@media only screen and (min-width: 768px) and (max-width: 991px) {

    .onepage-navbar {
        &.navbar {
            border-bottom: 1px solid #eeeeee;
            padding: {
                top: 15px;
                bottom: 15px;
            };
            .container {
                padding: {
                    left: 15px;
                    right: 15px;
                };
            }
            &.is-sticky {
                border-bottom: none;
            }
        }
        &.navbar-light {
            .navbar-toggler {
                color: $black-color;
                border: none;
                padding: 0;
    
                .burger-menu {
                    cursor: pointer;
                    transition: $transition;
        
                    span {
                        height: 2px;
                        width: 30px;
                        background: $black-color;
                        display: block;
                        margin: 6px 0;
                        transition: all .50s ease-in-out;
                    }
                    &.active {
                        span {
                            &.top-bar {
                                transform: rotate(45deg);
                                transform-origin: 10% 10%;
                            }
                            &.middle-bar {
                                opacity: 0;
                            }
                            &.bottom-bar {
                                transform: rotate(-45deg);
                                transform-origin: 10% 90%;
                                margin-top: 5px;
                            }
                        }
                    }
                }
            }
            .navbar-nav {
                background-color: $white-color;
                border: 1px solid #eeeeee;
                margin-top: 15px;
                padding: {
                    left: 15px;
                    right: 15px;
                    top: 8px;
                    bottom: 8px;
                };
                .nav-item {
                    .nav-link {
                        padding: {
                            left: 0;
                            right: 0;
                            top: 8px;
                            bottom: 8px;
                        };
                        margin: {
                            left: 0;
                            right: 0;
                        };
                    }
                }
            }
        }
    }

}

/*================================================
Search Overlay CSS
=================================================*/
.search-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99999;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    overflow: hidden;

    .search-overlay-layer {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        transform: translateX(100%);

        &:nth-child(1) {
            left: 0;
            background-color: rgba(0, 0, 0, 0.5);
            transition: all 0.3s ease-in-out 0s;
        }
        &:nth-child(2) {
            left: 0;
            background-color: rgba(0, 0, 0, 0.4);
            transition: all 0.3s ease-in-out 0.3s;
        }
        &:nth-child(3) {
            left: 0;
            background-color: rgba(0, 0, 0, 0.7);
            transition: all 0.9s ease-in-out 0.6s;
        }
    }
    .search-overlay-close {
        position: absolute;
        top: 40px;
        right: 40px;
        width: 50px;
        z-index: 2;
        text-align: center;
        cursor: pointer;
        padding: 10px;
        transition: all 0.9s ease-in-out 1.5s;
        opacity: 0;
        visibility: hidden;

        .search-overlay-close-line {
            width: 100%;
            height: 3px;
            float: left;
            margin-bottom: 5px;
            background-color: $white-color;
            transition: all 500ms ease;

            &:nth-child(1) {
                transform: rotate(45deg);
            }
            &:nth-child(2) {
                margin-top: -7px;
                transform: rotate(-45deg);
            }
        }
        &:hover {
            .search-overlay-close-line {
                background: $main-color;
                transform: rotate(180deg);
            }
        }
    }
    .search-overlay-form {
        transition: all 0.9s ease-in-out 1.4s;
        opacity: 0;
        visibility: hidden;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
        z-index: 2;
        max-width: 500px;
        width: 500px;

        form {
            position: relative;

            .input-search {
                display: block;
                width: 100%;
                height: 55px;
                border: none;
                border-radius: 30px;
                padding-left: 20px;
                color: $black-color;
                font-size: $font-size;
            }
            button {
                position: absolute;
                right: 5px;
                top: 5px;
                width: 45px;
                color: $white-color;
                height: 45px;
                border-radius: 50%;
                background-color: $main-color;
                transition: $transition;
                border: none;
                font-size: 20px;
                line-height: 45px;

                &:hover {
                    background-color: $black-color;
                    color: $white-color;
                }
            }
        }
    }
    &.search-overlay-active {
        &.search-overlay {
            opacity: 1;
            visibility: visible;
    
            .search-overlay-layer {
                transform: translateX(0);
            }
            .search-overlay-close {
                opacity: 1;
                visibility: visible;
            }
            .search-overlay-form {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

/*================================================
Main Banner Area CSS
=================================================*/
.main-banner {
    position: relative;
    z-index: 1;
    padding-top: 30px;
    background: {
        position: center center;
        size: cover;
        repeat: no-repeat;
    };
    &.banner-bg1 {
        background-image: url(../../assets/img/main-banner1.jpg);
    }
    &.banner-bg2 {
        background-image: url(../../assets/img/main-banner2.jpg);
    }
    &.banner-bg3 {
        background-image: url(../../assets/img/main-banner3.jpg);
    }
    &.banner-bg4 {
        background-image: url(../../assets/img/main-banner6.jpg);
    }
    &.banner-bg5 {
        background-image: url(../../assets/img/main-banner7.jpg);
    }
}
.main-banner-content {
    .sub-title {
        color: $white-color;
        display: block;
        position: relative;
        padding-left: 25px;
        font: {
            size: 18px;
            weight: 700;
        };
        &::before {
            width: 15px;
            height: 3px;
            background-color: $main-color;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            content: '';
        }
    }
    h1 {
        color: $white-color;
        font: {
            size: 55px;
            weight: 800;
        };
        margin: {
            top: 12px;
            bottom: 18px;
        };
    }
    p {
        color: $white-color;
        opacity: 0.95;
    }
    .btn-box {
        margin-top: 30px;

        .default-btn {
            border-color: $main-color;

            &:hover {
                color: $black-color;
                background-color: $white-color;
                border-color: $white-color;
            }
        }
        .optional-btn {
            margin-left: 15px;
            color: $black-color;
            border-color: $white-color;
            background-color: $white-color;

            &:hover {
                color: $white-color;
                border-color: $main-color;
                background-color: $main-color;
            }
        }
    }
}
.main-banner-image {
    text-align: center;
    overflow: hidden;
}
.home-slides {
    &.owl-theme {
        .owl-nav {
            margin-top: 0;
            line-height: 1;

            [class*=owl-] {
                position: absolute;
                left: 0;
                top: 50%;
                margin: 0;
                padding: 0;
                background-color: transparent !important;
                color: $white-color !important;
                font-size: 35px;
                opacity: 0;
                visibility: hidden;
                transform: translateY(-50%);
                transition: $transition;

                &.owl-next {
                    left: auto;
                    right: 0;
                }
            }
        }
        &:hover {
            .owl-nav {    
                [class*=owl-] {
                    left: 20px;
                    opacity: 1;
                    visibility: visible;
    
                    &.owl-next {
                        left: auto;
                        right: 20px;
                    }
                }
            }
        }
    }
}
.banner-section {
    position: relative;
    z-index: 1;
    padding: {
        top: 100px;
        bottom: 60px;
    };
    background: {
        position: center center;
        size: cover;
        repeat: no-repeat;
    };
    &.banner-bg1 {
        background-image: url(../../assets/img/main-banner1.jpg);
    }
    &.banner-bg2 {
        background-image: url(../../assets/img/main-banner2.jpg);
    }
    &.banner-bg3 {
        background-image: url(../../assets/img/main-banner3.jpg);
    }
    .main-banner-image {
        text-align: start;
    }
    .main-banner-content {
        max-width: 620px;
        margin-left: auto;

        h1 {
            text-transform: capitalize;
            font-size: 47px;
        }
        p {
            max-width: 600px;
        }
    }
}
.hero-banner {
    position: relative;
    z-index: 1;
    background: linear-gradient(to left bottom, #fff6f6, #ffefe8, #ffecd4, #f8ecc3, #ddefbb);
    background: {
        image: url(../../assets/img/banner-bg.jpg);
        position: center center;
        repeat: no-repeat;
        size: cover;
    };
    padding: {
        top: 170px;
        bottom: 40px;
    };
}
.hero-banner-content {
    text-align: center;

    h1 {
        margin-bottom: 30px;
        font: {
            size: 55px;
            weight: 800;
        };
    }
    .banner-box-list {
        margin-bottom: 35px;
        padding: {
            left: 20px;
            right: 20px;
        };
        .single-banner-box {
            border: 1px solid #d9e1f2;
            border-radius: 5px;
            position: relative;
            padding: 15px 20px 15px 102px;
            text-align: start;

            .icon {
                position: absolute;
                left: 20px;
                top: 50%;
                transform: translateY(-50%);
            }
            h3 {
                margin-bottom: 0;
                font: {
                    size: 36px;
                    weight: 800;
                };
            }
            p {
                line-height: initial;
                margin-top: 4px;
                color: $main-color;
                font: {
                    size: 16.5px;
                    weight: 500;
                };
            }
        }
        .row {
            .col-lg-3 {
                &:nth-child(2) {
                    .single-banner-box {
                        h3 {
                            color: #ba9500;
                        }
                    }
                }
                &:nth-child(3) {
                    .single-banner-box {
                        h3 {
                            color: #af1a1b;
                        }
                    }
                }
                &:nth-child(4) {
                    .single-banner-box {
                        h3 {
                            color: #557d20;
                        }
                    }
                }
            }
        }
    }
}
.banner-wrapper {
    position: relative;
    height: 100vh;
    z-index: 1;
    background: {
        position: center center;
        size: cover;
        repeat: no-repeat;
    };
    &.banner-bg1 {
        background-image: url(../../assets/img/main-banner4.jpg);
    }
}
.banner-wrapper-content {
    max-width: 600px;
    margin: {
        left: auto;
        top: 50px;
    };
    h1 {
        color: $white-color;
        line-height: 1.3;
        font: {
            size: 50px;
            weight: 800;
        };
        margin: {
            bottom: 15px;
        };
    }
    p {
        color: $white-color;
        font-size: 16px;
    }
    .btn-box {
        margin-top: 30px;

        .default-btn {
            border-color: $main-color;

            &:hover {
                color: $black-color;
                background-color: $white-color;
                border-color: $white-color;
            }
        }
        .optional-btn {
            margin-left: 15px;
            color: $black-color;
            border-color: $white-color;
            background-color: $white-color;

            &:hover {
                color: $white-color;
                border-color: $main-color;
                background-color: $main-color;
            }
        }
    }
}
.banner-area {
    overflow: hidden;
    position: relative;
    z-index: 1;
    height: 750px;
    background: {
        color: $main-color;
        image: url(../../assets/img/main-banner5.jpg);
        position: center center;
        size: cover;
        repeat: no-repeat;
    };
    .main-banner-content {
        .sub-title {
            &::before {
                background-color: $white-color;
            }
        }
        .btn-box {
            .optional-btn {
                margin-left: 0;
                
                &:hover {
                    background-color: $black-color;
                    color: $white-color;
                    border-color: $black-color;
                }
            }
        }
    }
}
.banner-image {
    position: relative;
    width: 100%;
    height: 100%;

    img {
        position: absolute;

        &:nth-child(1) {
            left: 20px;
            top: -80px;
            animation: {
                name: rotateme;
                duration: 60s;
                iteration-count: infinite;
                timing-function: linear;
            };
        }
        &:nth-child(2) {
            top: 30px;
            right: 0;
            animation: {
                name: rotatemetwo;
                duration: 60s;
                iteration-count: infinite;
                timing-function: linear;
            };
        }
        &:nth-child(3) {
            left: -35px;
            bottom: -80px;
            right: 0;
            text-align: center;
            margin: {
                left: auto;
                right: auto;
            };
            animation: {
                name: rotateme;
                duration: 60s;
                iteration-count: infinite;
                timing-function: linear;
            };
        }
    }
}
@keyframes rotatemetwo {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(-360deg);
    }
}
.main-banner-content {
    &.black-text {
        .sub-title {
            color: $black-color;

            &::before {
                background-color: $main-color;
            }
        }
        h1 {
            color: $black-color;
        }
        p {
            color: $black-color;
            opacity: 1;
        }
    }
}
.home-slides-two {
    .main-banner {
        padding-top: 0;
        height: 800px;
        background-position: top center;

        .main-banner-content {
            .sub-title {
                margin-bottom: 15px;
            }
        }
    }
    &.owl-theme {
        .owl-nav {
            margin-top: 0;
            line-height: 1;

            [class*=owl-] {
                position: absolute;
                left: 0;
                top: 50%;
                margin: 0;
                padding: 0;
                background-color: transparent !important;
                color: $black-color !important;
                font-size: 35px;
                opacity: 0;
                visibility: hidden;
                transform: translateY(-50%);
                transition: $transition;

                &.owl-next {
                    left: auto;
                    right: 0;
                }
                &:hover {
                    color: $main-color !important;
                }
            }
        }
        &:hover {
            .owl-nav {    
                [class*=owl-] {
                    left: 20px;
                    opacity: 1;
                    visibility: visible;
    
                    &.owl-next {
                        left: auto;
                        right: 20px;
                    }
                }
            }
        }
    }
}

/*================================================
About Area CSS
=================================================*/
.about-image {
    position: relative;
    text-align: start;
    z-index: 1;
    padding: {
        right: 80px;
        bottom: 40px;
    };
    img {
        border-radius: 5px;

        &:nth-child(2) {
            box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
            position: absolute;
            right: 0;
            bottom: 0;
            border-radius: 50%;
        }
    }
}
.about-content {
    padding-left: 15px;

    .sub-title {
        text-transform: uppercase;
        margin-bottom: 10px;
        color: $main-color;
        display: block;
        font: {
            size: 14px;
            weight: 600;
        };
    }
    h2 {
        margin-bottom: 13px;
        font: {
            size: 35px;
            weight: 800;
        };
    }
    blockquote {
        background-color: #f4f4f4;
        padding: 20px;
        border-left: 4px solid $main-color;

        p {
            margin-bottom: 0;
            color: $black-color;
            font: {
                size: 17px;
                weight: 600;
                style: italic;
            };
        }
    }
    .default-btn {
        margin-top: 5px;
    }
}
.about-section {
    position: relative;
    z-index: 1;
    background-color: #f9f9f9;
}
.about-img {
    text-align: center;
}
.about-shape1 {
    position: absolute;
    left: 2%;
    bottom: 15%;
    z-index: -1;
}
.about-area-two {
    position: relative;
    z-index: 1;
}
.about-image-two {
    text-align: center;

    img {
        border-radius: 5px;
    }
}
.about-content-two {
    padding-left: 15px;

    .sub-title {
        text-transform: uppercase;
        margin-bottom: 10px;
        color: $main-color;
        display: block;
        font: {
            size: 14px;
            weight: 600;
        };
    }
    h2 {
        margin-bottom: 18px;
        font: {
            size: 35px;
            weight: 800;
        };
    }
    h6 {
        margin-bottom: 14px;
        line-height: 1.6;
        font-weight: 600;
    }
    .features-text {
        margin-top: 25px;

        h5 {
            margin-bottom: 22px;
            position: relative;
            font: {
                size: 17px;
                weight: 600;
            };
            padding: {
                left: 47px;
                top: 7px;
            };
        }
        i {
            position: absolute;
            left: 0;
            top: 0;
            width: 35px;
            height: 35px;
            background-color: $main-color;
            text-align: center;
            line-height: 35px;
            color: $white-color;
            font-size: 20px;
            box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .10);
            border-radius: 50%;
        }
    }
    .features-list {
        display: flex;
        flex-wrap: wrap;
        padding-left: 0;
        list-style-type: none;
        margin: {
            bottom: 0;
            top: -10px;
            left: -10px;
            right: -10px;
        };
        li {
            flex: 0 0 50%;
            max-width: 50%;
            margin-top: 20px;
            padding: {
                left: 10px;
                right: 10px;
            };
            span {
                display: block;
                position: relative;
                padding-left: 34px;
                font: {
                    size: 16px;
                    weight: 600;
                };
                i {
                    font-size: 16px;
                    color: $white-color;
                    background-color: $main-color;
                    width: 25px;
                    height: 23px;
                    line-height: 23px;
                    text-align: center;
                    border-radius: 3px;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }
}
.about-inner-area {
    margin-top: 80px;

    &:first-child {
        margin-top: 0;
    }
    &:nth-child(2), &:nth-child(4), &:nth-child(6), &:nth-child(8) {
        .about-content-two {
            padding: {
                left: 0;
                right: 15px;
            };
        }
    }
}

/*================================================
CTA Area CSS
=================================================*/
.cta-inner-area {
    border-radius: 10px;
    margin-top: 20px;
    background: {
        color: $black-color;
        image: url(../../assets/img/cta-bg.jpg);
        position: center center;
        size: cover;
        repeat: no-repeat;
    };
    padding: {
        left: 50px;
        right: 50px;
    };
    img {
        text-align: center;
        display: inline-block;
        top: -70px;
        position: relative;
        margin-bottom: -70px;
    }
    h3 {
        color: $white-color;
        margin-bottom: 10px;
        font: {
            size: 22px;
            weight: 800;
        };
    }
    p {
        line-height: initial;
        font-weight: 600;
        opacity: 0.95;
        color: $white-color;
    }
    .cta-btn {
        text-align: center;

        a {
            background-color: $main-color;
            color: $white-color;
            text-align: start;
            position: relative;
            padding: 13px 30px 13px 90px;
            display: inline-block;
            border-radius: 50px;
            font: {
                size: 24px;
                weight: 800;
            };
            span {
                color: $white-color;
                display: block;
                text-transform: uppercase;
                margin-bottom: 3px;
                font: {
                    size: 14px;
                    weight: 600;
                };
            }
            i {
                position: absolute;
                font-size: 45px;
                left: 30px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
}

/*================================================
Spreads Virus Area CSS
=================================================*/
.spread-virus-area {
    position: relative;
    z-index: 1;
}
.single-spread-virus-box {
    margin-bottom: 30px;
    border: 1px solid #d5e2fe;
    padding: 20px;
    border-radius: 5px;
    transition: $transition;

    img {
        border-radius: 5px;
    }
    h3 {
        transition: $transition;
        margin: {
            top: 25px;
            bottom: 10px;
        };
        font: {
            size: 20px;
            weight: 800;
        };
    }
    p {
        transition: $transition;
        margin-bottom: 12px;
    }
    .read-more-btn {
        text-transform: uppercase;
        display: inline-block;
        font: {
            size: 14px;
            weight: 700;
        };
    }
    &:hover {
        background-color: $black-color;
        border-color: $black-color;

        h3 {
            color: $white-color;
        }
        p {
            color: $white-color;
        }
        .read-more-btn {
            color: $white-color;
        }
    }
}
.how-to-spread-area {
    position: relative;
    z-index: 1;

    &.bg-white-color {
        padding-top: 40px;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: -60px;
            height: 60px;
            border-radius: 5px 5px 0 0;
            background-color: $white-color;
            width: 100%;
            max-width: 1230px;
            margin: {
                left: auto;
                right: auto;
            };
        }
    }
}
.overview-box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: {
        left: -15px;
        right: -15px;
    };
    .overview-image {
        flex: 0 0 50%;
        max-width: 50%;
        padding: {
            left: 15px;
            right: 15px;
        };
        .image {
            position: relative;
            text-align: center;
            margin-right: 15px;

            .main-image {
                border-radius: 50%;
                padding: 60px;
            }
            .shape {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;

                img {
                    &:nth-child(1) {
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 35px;
                        margin: {
                            left: auto;
                            right: auto;
                        };
                        animation: {
                            name: rotateme;
                            duration: 70s;
                            iteration-count: infinite;
                            timing-function: linear;
                        };
                    }
                    &:nth-child(2) {
                        position: absolute;
                        right: 110px;
                        display: none;
                        bottom: 60px;
                        animation: movebounce 7s linear infinite;
                    }
                }
            }
        }
    }
    .overview-content {
        flex: 0 0 50%;
        max-width: 50%;
        padding: {
            left: 15px;
            right: 15px;
        };
        .content {
            padding-left: 15px;

            .icon {
                margin-bottom: 25px;
            }
            h3 {
                line-height: 1.4;
                margin-bottom: 15px;
                font: {
                    size: 22px;
                    weight: 800;
                };
            }
        }
    }
    &:nth-child(2) {
        margin-top: -35px;
    }
    &:last-child {
        margin-bottom: -35px;
    }
}
.spread-shape1 {
    position: absolute;
    right: 0;
    z-index: -1;
    opacity: 0.4;
    top: 18%;
    transform: translateY(-18%);
}
.spread-shape2 {
    position: absolute;
    left: 0;
    z-index: -1;
    opacity: 0.4;
    top: 75%;
    transform: translateY(-75%);
}
.spread-virus-content {
    margin-left: auto;
    max-width: 585px;
    margin-bottom: 40px;

    .sub-title {
        text-transform: uppercase;
        margin-bottom: 10px;
        color: $main-color;
        display: block;
        font: {
            size: 14px;
            weight: 600;
        };
    }
    h2 {
        margin-bottom: 10px;
        font: {
            size: 35px;
            weight: 800;
        };
    }
    ul {
        padding-left: 0;
        list-style-type: none;
        margin: {
            bottom: 0;
            top: 30px;
        };
        li {
            margin-bottom: 20px;
            position: relative;
            padding-left: 105px;

            .icon {
                position: absolute;
                width: 85px;
                height: 85px;
                text-align: center;
                border-radius: 50%;
                background-color: #ebf0fa;
                left: 0;
                top: 0;

                img {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    margin: {
                        left: auto;
                        right: auto;
                    };
                }
                i {
                    color: $white-color;
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 35px;
                    margin: {
                        left: auto;
                        right: auto;
                    };
                }
            }
            h3 {
                margin-bottom: 8px;
                font: {
                    size: 20px;
                    weight: 700;
                };
            }
            &:last-child {
                margin-bottom: 0;
            }
            p {
                max-width: 320px;
                font-size: 15px;
            }
        }
    }
}
.spread-virus-image {
    text-align: center;
    padding-top: 200px;
    position: relative;
    left: -30px;
}
.spread-shape3 {
    position: absolute;
    left: 1%;
    bottom: 0;
    z-index: -1;

    img {
        max-width: 60%;
    }
}

/*================================================
Symptoms Area CSS
=================================================*/
.symptoms-area {
    position: relative;
    overflow: hidden;
    z-index: 1;
    background: {
        color: #f1f5fe;
        image: url(../../assets/img/symptoms-bg.jpg);
        position: -270px center;
        size: cover;
        repeat: no-repeat;
    };
}
.symptoms-image {
    position: relative;
    left: -30px;
}
.symptoms-content {
    padding-left: 25px;

    .sub-title {
        text-transform: uppercase;
        margin-bottom: 10px;
        color: $main-color;
        display: block;
        font: {
            size: 14px;
            weight: 600;
        };
    }
    h2 {
        margin-bottom: 0;
        font: {
            size: 35px;
            weight: 800;
        };
    }
    p {
        margin: {
            bottom: 0;
            top: 10px;
        };
    }
    ul {
        padding-left: 0;
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        margin: {
            left: -10px;
            right: -10px;
            bottom: 0;
            top: 10px;
        };
        li {
            flex: 0 0 50%;
            max-width: 50%;
            padding: {
                left: 10px;
                right: 10px;
            };
            span {
                margin-top: 20px;
                display: block;
                background-color: $white-color;
                position: relative;
                padding: 15px 15px 15px 55px;
                color: $black-color;
                transition: $transition;
                border-radius: 0 5px 5px 0;
                z-index: 1;
                font: {
                    size: 18px;
                    weight: 800;
                };
                &::before {
                    width: 3px;
                    height: 100%;
                    z-index: -1;
                    background-color: $black-color;
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    border-radius: 0 5px 5px 0;
                    transition: $transition;
                }
                i {
                    position: absolute;
                    left: 15px;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 30px;
                    height: 30px;
                    text-align: center;
                    line-height: 30px;
                    color: $black-color;
                    font-size: 14px;
                    background-color: #e5ecfe;
                    border-radius: 50%;
                }
                &:hover {
                    color: $white-color;

                    &::before {
                        width: 100%;
                    }
                }
            }
        }
    }
}
.symptoms-section {
    position: relative;
    z-index: 1;

    &.bg-white-color {
        padding-top: 40px;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: -60px;
            height: 60px;
            border-radius: 5px 5px 0 0;
            background-color: $white-color;
            width: 100%;
            max-width: 1230px;
            margin: {
                left: auto;
                right: auto;
            };
        }
    }
}
.symptoms-box-list {
    text-align: center;

    .box-item {
        position: relative;
        margin-bottom: 30px;
        padding: {
            left: 20px;
            right: 20px;
        };
        .img {
            margin-bottom: 20px;
        }
        h3 {
            margin-bottom: 12px;
            font: {
                weight: 800;
                size: 22px;
            };
        }
        p {
            font-size: 15px;
        }
        &:nth-child(1) {
            left: 70px;
        }
        &:nth-child(3) {
            left: 70px;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.row {
    .col-lg-4 {
        &:nth-child(3) {
            .symptoms-box-list {            
                .box-item {
                    &:nth-child(1) {
                        left: -70px;
                    }
                    &:nth-child(3) {
                        left: -70px;
                    }
                }
            }
        }
    }
}
.symptoms-img {
    position: relative;
    left: -30px;
    text-align: center;

    img {
        max-width: 115%;
    }
}
.symptoms-shape1 {
    position: absolute;
    right: 1%;
    top: 15%;
    z-index: -1;

    img {
        animation: movebounce 7s linear infinite;
    }
}
.symptoms-shape2 {
    position: absolute;
    left: 0;
    bottom: 15%;
    z-index: -1;

    img {
        animation: movebounce 7s linear infinite;
    }
}

/*================================================
Funfacts Area CSS
=================================================*/
.funfacts-area {
    position: relative;
    z-index: 1;
    background: {
        image: url(../../assets/img/funfacts-bg.jpg);
        position: center center;
        size: cover;
        repeat: no-repeat;
    };
    .section-title {
        h2 {
            color: $white-color;
        }
    }
    &::before {
        width: 100%;
        height: 100%;
        background-color: $black-color;
        opacity: 0.75;
        content: '';
        position: absolute;
        z-index: -1;
        left: 0;
        top: 0;
    }
}
.funfacts-list {
    position: relative;

    .row {
        .col-lg-6 {
            &:nth-child(1) {
                .single-funfacts-box {
                    margin-bottom: 30px;
                }
            }
            &:nth-child(2) {
                .single-funfacts-box {
                    margin-bottom: 30px;
                }
            }
        }
    }
    .single-funfacts-box {
        margin-bottom: 0;
    }
}
.single-funfacts-box {
    margin-bottom: 30px;
    background-color: $black-color;
    text-align: center;
    padding: 20px;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    z-index: 1;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: {
            image: url(../../assets/img/funfacts-shape.png);
            position: center center;
            size: cover;
            repeat: no-repeat;
        };
    }
    .icon {
        margin-bottom: 15px;
    }
    h3 {
        margin-bottom: 0;
        color: $white-color;
        font: {
            size: 40px;
            weight: 800;
        };
    }
    p {
        line-height: 1;
        color: $main-color;
        margin-top: 9px;
        font: {
            size: 16px;
            weight: 600;
        };
    }
    &.bg-white {
        background-color: $white-color;
        border: 1px solid #e5edff;

        &::before {
            background: {
                image: url(../../assets/img/funfacts-shape2.png);
            };
        }
        h3 {
            color: $black-color;
        }
    }
}
.funfacts-info {
    text-align: end;
    margin-top: 15px;

    p {
        color: $white-color;
        font: {
            size: 11px;
            weight: 600;
        };
        a {
            color: $white-color;
            text-decoration: underline;
        }
    }
    &.color-black {
        p {
            color: $black-color;

            a {
                color: $black-color;
            }
        }
    }
}
.funfacts-section {
    position: relative;
    z-index: 1;

    .funfacts-image {
        max-width: 635px;
        margin: {
            top: 28px;
            left: auto;
        };
    }
    .funfacts-list {
        max-width: 585px;

        .row {
            .col-lg-6 {
                &:nth-child(1) {
                    .single-funfacts-box {
                        margin-top: 30px;
                    }
                }
                &:nth-child(4) {
                    .single-funfacts-box {
                        margin-top: -30px;
                    }
                }
            }
            .col-lg-12 {
                margin-top: -30px;
            }
        }
    }
}
.funfacts-shape1 {
    position: absolute;
    right: 3%;
    z-index: -1;
    opacity: .15;
    top: 55%;
    transform: translateY(-55%);
}

/*================================================
How To Protect Area CSS
=================================================*/
.how-to-protect-content {
    .content {
        z-index: 1;
        position: relative;
        margin: {
            top: 15px;
            bottom: 35px;
            left: 15px;
        };
        &::before {
            background-color: #f1f1f4;
            z-index: -1;
            width: 100%;
            height: 100%;
            border-radius: 5px;
            content: '';
            position: absolute;
            left: -15px;
            top: -15px;
            transition: $transition;
        }
        .text {
            cursor: pointer;
            background-color: #f9f9f9;
            border-radius: 5px;
            position: relative;
            padding: 30px 30px 30px 125px;

            .icon {
                width: 75px;
                height: 75px;
                border-radius: 5px;
                background-color: $main-color;
                color: $white-color;
                position: absolute;
                left: 30px;
                top: 48%;
                transform: translateY(-48%);
                font-size: 40px;
                text-align: center;

                i {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
            h3 {
                margin-bottom: 10px;
                font: {
                    size: 20px;
                    weight: 800;
                };
            }
            p {
                margin-bottom: 0;
            }
        }
        &:hover {
            &::before {
                background-color: $black-color;
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.how-to-protect-image {
    margin-left: 15px;
    position: relative;
    z-index: 1;
    height: 100%;

    .main-image {
        border-radius: 50%;
        border: 14px solid $black-color;
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        z-index: 1;
        transition: $transition;
        transform: translateY(-50%);

        &.main-image2 {
            opacity: 0;
            visibility: hidden;

            &.active {
                opacity: 1;
                visibility: visible;
            }
        }
        &.main-image3 {
            opacity: 0;
            visibility: hidden;

            &.active {
                opacity: 1;
                visibility: visible;
            }
        }
        &.main-image4 {
            opacity: 0;
            visibility: hidden;

            &.active {
                opacity: 1;
                visibility: visible;
            }
        }
        &.main-image5 {
            opacity: 0;
            visibility: hidden;

            &.active {
                opacity: 1;
                visibility: visible;
            }
        }
        &.main-image6 {
            opacity: 0;
            visibility: hidden;

            &.active {
                opacity: 1;
                visibility: visible;
            }
        }
    }
    .how-to-protect-shape {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;

        img {
            z-index: -1;
            position: absolute;

            &:nth-child(1) {
                left: 0;
                top: 0;
                animation: movebounce 7s linear infinite;
            }
            &:nth-child(2) {
                right: 0;
                top: 0;
                animation: moveleftbounce 7s linear infinite;
            }
            &:nth-child(3) {
                left: 0;
                bottom: 0;
                animation: {
                    name: rotateme;
                    duration: 150s;
                    iteration-count: infinite;
                    timing-function: linear;
                };
            }
            &:nth-child(4) {
                right: 0;
                bottom: 0;
                animation: movebounce 7s linear infinite;
            }
        }
    }
}
.how-to-protect-content-list {
    margin-left: auto;
    max-width: 470px;

    .content {
        margin-bottom: 30px;

        .text {
            position: relative;
            padding-left: 95px;

            .icon {
                width: 75px;
                height: 80px;
                border-radius: 5px;
                background-color: $main-color;
                color: $white-color;
                transition: $transition;
                position: absolute;
                left: 0;
                top: 0;
                font-size: 40px;
                text-align: center;

                i {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
            h3 {
                margin-bottom: 8px;
                font: {
                    size: 20px;
                    weight: 800;
                };
            }
            p {
                margin-bottom: 0;
            }
            &:hover {
                .icon {
                    background-color: $black-color;
                    color: $white-color;
                }
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.how-to-protect-img {
    text-align: center;
}

/*================================================
Prevention Area CSS
=================================================*/
.prevention-area {
    position: relative;
    z-index: 1;
    background-color: $black-color;

    .section-title {
        h2 {
            color: $white-color;
        }
    }
}
.prevention-content {
    margin-bottom: 30px;

    .title {
        background-color: $main-color;
        color: $white-color;
        text-align: center;
        position: relative;
        z-index: 1;
        padding: {
            top: 14px;
            bottom: 15px;
            left: 30px;
            right: 30px;
        };
        h3 {
            color: $white-color;
            margin-bottom: 0;
            font: {
                size: 24px;
                weight: 700;
            };
        }
        &::before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            width: 26px;
            z-index: -1;
            height: 26px;
            background-color: $main-color;
            bottom: -12px;
            transform: rotate(45deg);
            margin: {
                left: auto;
                right: auto;
            };
        }
    }
    .content-list {
        border: 1px solid #20256f;
        border-top: none;
        padding: 45px 30px 30px;

        .content {
            position: relative;
            border-bottom: 1px solid #20256f;
            margin-bottom: 25px;
            padding: {
                bottom: 25px;
                left: 110px;
            };
            .icon {
                position: absolute;
                left: 0;
                top: 1px;
                width: 90px;
                height: 90px;
                border: 1px solid #20256f;
                z-index: 1;
                background-color: transparent;
                text-align: center;
                border-radius: 50%;

                img {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    margin: {
                        left: auto;
                        right: auto;
                    };
                }
                &::before {
                    background-color: #1b195e;
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    z-index: -1;
                    border-radius: 50%;
                    margin: 5px;
                }
                i {
                    color: $white-color;
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 35px;
                    margin: {
                        left: auto;
                        right: auto;
                    };
                }
            }
            h3 {
                margin-bottom: 10px;
                color: $white-color;
                font: {
                    size: 20px;
                    weight: 700;
                };
            }
            p {
                font-size: 15px;
                color: #dedede;
            }
            &:last-child {
                margin-bottom: 0;
                padding-bottom: 0;
                border-bottom: none;
            }
        }
    }
}
.col-lg-6 {
    &:nth-child(2) {
        .prevention-content {
            .title {
                background-color: #c35362;

                &::before {
                    background-color: #c35362;
                }
            }
        }
    }
}
.prevention-shape1 {
    position: absolute;
    left: 2%;
    bottom: 20%;
    z-index: -1;

    img {
        animation: {
            name: rotateme;
            duration: 80s;
            iteration-count: infinite;
            timing-function: linear;
        };
    }
}
.prevention-shape2 {
    position: absolute;
    left: 8.3%;
    bottom: 38%;
    display: none;
    z-index: -1;
    
    img {
        animation: movebounce 10s linear infinite;
    }
}
.prevention-shape3 {
    position: absolute;
    right: 9.5%;
    top: 19%;
    z-index: -1;

    img {
        animation: {
            name: rotateme;
            duration: 80s;
            iteration-count: infinite;
            timing-function: linear;
        };
    }
}
.prevention-shape4 {
    display: none;
    position: absolute;
    right: 8%;
    bottom: 18%;
    z-index: -1;

    img {
        animation: movescale 8s linear infinite;
    }
}

/*================================================
FAQ Area CSS
=================================================*/
.faq-area {
    position: relative;
    z-index: 1;
}
.faq-image {
    position: relative;
    height: 100%;
    background-size: {
        position: center center;
        size: cover;
        repeat: no-repeat;
    };
    img {
        display: none;
    }
    &.faq-bg1 {
        background-image: url(../../assets/img/faq-img.jpg);
    }
    .video-btn {
        display: inline-block;
        width: 80px;
        height: 80px;
        font-size: 35px;
        text-align: center;
        line-height: 80px;
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 1;
        transform: translateY(-50%) translateX(-50%);
        background-color: $main-color;
        border-radius: 50%;
        color: $white-color;
    
        &::after, &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            bottom: 0;
            left: 0;
            transition: $transition;
            border-radius: 50%;
            border: 1px solid $main-color;
        }
        &::before {
            animation: ripple 2s linear infinite;
        }
        &::after {
            animation: ripple 2s linear 1s infinite;
        }
        &:hover {
            color: $white-color;
            background-color: $black-color;
    
            &::before, &::after {
                border-color: $black-color;
            }
        }
    }
}
.faq-accordion {
    .accordion-item {
        display: block;
        box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.13) !important;
        background: $white-color;
        margin-bottom: 15px;
        border-radius: 0;
        border: none;

        &:last-child {
            margin-bottom: 0;
        }
        .accordion-header {
            padding: 0;
            border-bottom: none;
            border-radius: 0 !important;
            background-color: $white-color;

            .accordion-button {
                background-color: transparent;
                padding: 12px 35px 10px 15px;
                color: $black-color;
                position: relative;
                box-shadow: unset;
                display: block;
                border: {
                    left: 3px solid $black-color;
                    bottom: 1px solid #eeeeee;
                };
                font: {
                    size: 16px;
                    weight: 600;
                };
                i {
                    position: absolute;
                    right: 15px;
                    top: 50%;
                    font-size: 14px;
                    transform: translateY(-50%);

                    &::before {
                        content: "\f10a";
                    }
                }
                &.collapsed {
                    border: {
                        bottom: {
                            color: transparent;
                        };
                    };
                    i {
                        &::before {
                            content: "\f109";
                        }
                    }
                }
                &::after {
                    display: none;
                }
            }
        }
    }
}
.faq-shape1 {
    position: absolute;
    left: 0;
    bottom: 25px;
    z-index: -1;
    opacity: .1;
    
    img {
        animation: movebounce 15s linear infinite;
    }
}
.faq-shape2 {
    position: absolute;
    right: 10px;
    top: 10px;
    opacity: .1;
    z-index: -1;
    
    img {
        animation: movebounce 20s linear infinite;
    }
}
@keyframes ripple {
    0% {
        transform: scale(1);
    }
    75% {
        transform: scale(1.75);
        opacity: 1;
    }
    100% {
        transform: scale(2);
        opacity: 0;
    }
}
.tab {
    .tabs-item {
        display: none;
        
        &:first-child {
            display: block;
        }
    }
}
.faq-accordion-tab {
    .tabs {
        padding-left: 0;
        list-style-type: none;
        margin-bottom: 50px;
        text-align: center;

        li {
            margin: {
                left: 10px;
                right: 10px;
            };
            a {
                color: $black-color;
                text-decoration: none;
                border-radius: 5px;
                padding: 20px;
                display: block;
                box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, .13)!important;
                font: {
                    size: 17px;
                    weight: 600;
                };
                i {
                    font-size: 30px;
                }
                span {
                    display: block;
                    margin-top: 8px;
                }
                &:hover {
                    background-color: $main-color;
                    color: $white-color;
                }
            }
            &.current {
                a {
                    background-color: $main-color;
                    color: $white-color;
                }
            }
        }
    }
}
.faq-accordion-content {
    .card {
        display: block;
        box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.13) !important;
        background: $white-color;
        margin-bottom: 15px;
        border-radius: 0;
        border: none;

        &:last-child {
            margin-bottom: 0;
        }
        .card-header {
            border-radius: 0 !important;
            padding: 0;
            border-bottom: none;
            background-color: $white-color;

            a {
                padding: 12px 35px 10px 15px;
                color: $black-color;
                text-decoration: none;
                position: relative;
                display: block;
                border: {
                    left: 3px solid $black-color;
                    bottom: 1px solid #eeeeee;
                };
                font: {
                    size: 16px;
                    weight: 600;
                };
                i {
                    position: absolute;
                    right: 15px;
                    top: 50%;
                    font-size: 14px;
                    transform: translateY(-50%);

                    &::before {
                        content: "\f10a";
                    }
                }
                &.collapsed {
                    border: {
                        bottom: {
                            color: transparent;
                        };
                    };
                    i {
                        &::before {
                            content: "\f109";
                        }
                    }
                }
            }
        }
        .collapse {
            .card-body {
                position: relative;
                padding: 20px;
            }
        }
    }
}
.faq-section {
    position: relative;
    z-index: 1;
    background-color: #f9f9f9;

    .faq-shape1 {
        opacity: 0.18;
        bottom: 35%;
    }
    .faq-shape2 {
        opacity: 0.18;
    }
    .faq-accordion {
        .accordion {
            .accordion-title {
                border-left-color: $main-color;

                i {
                    color: $main-color;
                }
            }
        }
    }
    &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        z-index: -1;
        background-color: $white-color;
        height: 20%;
    }
}
.faq-img {
    text-align: center;
    max-width: 810px;
    border-radius: 5px;
    position: relative;
    margin: {
        left: auto;
        right: auto;
        top: 50px;
    };
    img {
        border-radius: 5px;
    }
    .video-btn {
        display: inline-block;
        width: 80px;
        height: 80px;
        font-size: 35px;
        text-align: center;
        line-height: 80px;
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 1;
        transform: translateY(-50%) translateX(-50%);
        background-color: $white-color;
        border-radius: 50%;
        color: $black-color;
    
        &::after, &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            bottom: 0;
            left: 0;
            transition: $transition;
            border-radius: 50%;
            border: 1px solid $white-color;
        }
        &::before {
            animation: ripple 2s linear infinite;
        }
        &::after {
            animation: ripple 2s linear 1s infinite;
        }
        &:hover {
            color: $white-color;
            background-color: $main-color;
    
            &::before, &::after {
                border-color: $main-color;
            }
        }
    }
}

/*================================================
Appointment Area CSS
=================================================*/
.appointment-form {
    max-width: 900px;
    background-color: $white-color;
    box-shadow: 0px 8px 16px 0px rgba(146, 184, 255, 0.2);
    padding: 50px;
    margin: {
        left: auto;
        right: auto;
    };
    form {
        text-align: center;

        .form-group {
            text-align: start;

            label {
                display: block;
                margin-bottom: 8px;
                text-transform: uppercase;
                font: {
                    weight: 600;
                    size: 14px;
                };
                .required {
                    color: red;
                }
            }
        }
        .default-btn {
            margin-top: 10px;
        }
        .nice-select {
            float: unset;
            line-height: 48px;
            color: $optional-color;
            text-transform: capitalize;
            border: none;
            background-color: #f5f5f5 !important;
            font: {
                weight: 600;
                size: 14px;
            };
            padding: {
                top: 0;
                bottom: 0;
            };
            .list {
                background-color: $white-color;
                box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
                border-radius: 0;
                margin-top: 0;
                width: 100%;
                padding: {
                    top: 10px;
                    bottom: 10px;
                };
                .option {
                    transition: $transition;
                    padding: {
                        left: 20px;
                        right: 20px;
                    }
                    &:hover {
                        background-color: $main-color !important;
                        color: $white-color;
                    }
                    &.selected {
                        background-color: transparent;
                        font-weight: 600;
                    }
                }
            }
            &:after {
                right: 20px;
            }
        }
    }
}

/*================================================
Doctors Area CSS
=================================================*/
.doctors-area {
    &.bg-color {
        background-color: $black-color;

        .section-title {
            .sub-title {
                color: $main-color;
            }
            h2 {
                color: $white-color;
            }
        }
    }
}
.single-doctors-box {
    position: relative;
    z-index: 1;
    transition: $transition;
    overflow: hidden;
    margin: {
        bottom: 30px;
    };
    .image {
        border-radius: 5px 5px 0 0;
        position: relative;

        img {
            border-radius: 5px 5px 0 0;
        }
        .social {
            list-style-type: none;
            padding-left: 0;
            margin-bottom: 0;
            width: 35px;
            text-align: center;
            opacity: 0;
            position: absolute;
            bottom: 35px;
            transition: all 0.3s ease 0s;

            li {
                display: block;

                a {
                    display: inline-block;
                    width: 35px;
                    height: 35px;
                    line-height: 35px;
                    background: #006e90;
                    font-size: 17px;
                    color: $white-color;
                    position: relative;
                    transform: translate(-35px, 35px);
                    transition: all 0.3s ease-in-out 0s;

                    &:hover{
                        width: 40px;
                        background: $main-color;
                        transition-delay: 0s;
                    }
                }
                &:nth-child(1) {
                    a {
                        transition-delay: 0.4s;
                    }
                }
                &:nth-child(2) {
                    a {
                        transition-delay: 0.3s;
                    }
                }
                &:nth-child(3) {
                    a {
                        transition-delay: 0.2s
                    }
                }
                &:nth-child(4) {
                    a {
                        transition-delay: 0.1s;
                    }
                }
                &:nth-child(5) {
                    a {
                        transition-delay: 0s;
                    }
                }
            }
        }
    }
    .content {
        background-color: #f9f9f9;
        border-radius: 0 0 5px 5px;
        padding: 25px 40px;

        h3 {
            margin-bottom: 0;
            font: {
                size: 20px;
                weight: 800;
            };
        }
        span {
            display: block;
            margin-top: 8px;
            color: $main-color;
            font: {
                size: 15px;
                weight: 600;
            };
        }
    }
    &:hover {
        .image {
            .social {
                opacity: 1;

                li {
                    a {
                        transform: translate(0, 0)
                    }
                }
            }
        }
    }
}

/*================================================
Outbreak Area CSS
=================================================*/
.outbreak-area {
    position: relative;
    z-index: 1;
}
.outbreak-content {
    text-align: center;

    h1 {
        margin-bottom: 30px;
        font: {
            size: 55px;
            weight: 800;
        };
    }
    .outbreak-box-list {
        margin-bottom: 40px;
        padding: {
            left: 20px;
            right: 20px;
        };
        .single-outbreak-box {
            border: 1px solid #d9e1f2;
            border-radius: 5px;
            position: relative;
            padding: 15px 20px 15px 102px;
            text-align: start;

            .icon {
                position: absolute;
                left: 20px;
                top: 50%;
                transform: translateY(-50%);
            }
            h3 {
                margin-bottom: 0;
                font: {
                    size: 36px;
                    weight: 800;
                };
            }
            p {
                line-height: initial;
                margin-top: 4px;
                color: $main-color;
                font: {
                    size: 16.5px;
                    weight: 500;
                };
            }
        }
        .row {
            .col-lg-3 {
                &:nth-child(2) {
                    .single-outbreak-box {
                        h3 {
                            color: #ba9500;
                        }
                    }
                }
                &:nth-child(3) {
                    .single-outbreak-box {
                        h3 {
                            color: #af1a1b;
                        }
                    }
                }
                &:nth-child(4) {
                    .single-outbreak-box {
                        h3 {
                            color: #557d20;
                        }
                    }
                }
            }
        }
    }
    .dataTables_wrapper {
        margin-top: 20px;

        .dataTables_length {
            color: $optional-color;
            margin-bottom: 20px;
            position: relative;
            top: 10px;

            label {
                display: block;
                margin-bottom: 0;

                select {
                    border-color: #eeeeee;
                    cursor: pointer;
                    display: inline-block;
                    border-radius: 5px;
                    padding: 3px 5px;
                    margin: {
                        left: 8px;
                        right: 8px;
                    };
                }
            }
        }
        .dataTables_filter {
            margin-bottom: 20px;

            label {
                display: block;
                margin-bottom: 0;
                color: $optional-color;

                input {
                    border: 1px solid #ecf0f3;
                    height: 45px;
                    padding-left: 12px;
                    border-radius: 5px;
                }
            }
        }
        .dataTable {
            margin-top: 15px;
            border: none;

            thead {
                tr {
                    th {
                        border-color: #eeeeee;
                        text-align: start;
                        white-space: nowrap;
                        padding: 8px 15px;
                        vertical-align: middle;
                        font: {
                            size: 15.5px;
                            weight: 700;
                        };
                    }
                }
            }
            tbody {
                tr {
                    td {
                        text-align: start;
                        padding: 10px 15px;
                        vertical-align: middle;
                        border-color: #eeeeee;
                        white-space: nowrap;
                        color: $black-color;
                        border: {
                            top: none;
                            bottom: none;
                            right: none;
                        };
                        font: {
                            size: $font-size;
                            weight: 600;
                        };
                        &:last-child {
                            border-right: 1px solid #eeeeee;
                        }
                        &:first-child {
                            font-weight: 600;
                        }
                    }
                }
                .new_death {
                    background-color: red;
                    border: none !important;
                    color: $white-color;
                    font-weight: 600 !important;
                }
                .new_case {
                    background-color: #FFEEAA;
                    border: none !important;
                    font-weight: 600 !important;
                }
            }
            tfoot {
                tr {
                    th, td {
                        border-color: #eeeeee;
                        text-align: start;
                        padding: 8px 15px;
                        vertical-align: middle;
                        white-space: nowrap;
                        font: {
                            size: 15.5px;
                            weight: 700;
                        };
                    }
                }
            }
        }
        .dataTables_info {
            display: none;
        }
        .dataTables_paginate {
            &.paging_simple_numbers {
                display: none;
            }
        }
    }
}

/*================================================
Emergency Contact Area CSS
=================================================*/
.emergency-contact-inner {
    overflow: hidden;
    border-radius: 10px;
    padding: 30px 40px;
    background: {
        image: url(../../assets/img/emergency-contact-bg.jpg);
        color: $main-color;
        position: center center;
        size: cover;
        repeat: no-repeat;
    };
    .btn-box {
        .default-btn {
            background-color: $black-color;
            color: $white-color;
            border-color: $black-color;

            &:not(:first-child) {
                margin-left: 15px;
            }
            &:hover {
                color: $main-color;
                background-color: $white-color;
                border-color: $white-color;
            }
        }
    }
    .contact-cta {
        text-align: center;

        a {
            background-color: $black-color;
            color: $white-color;
            text-align: start;
            position: relative;
            padding: 13px 30px 13px 90px;
            display: inline-block;
            border-radius: 50px;
            font: {
                size: 24px;
                weight: 800;
            };
            span {
                color: $main-color;
                display: block;
                text-transform: uppercase;
                margin-bottom: 3px;
                font: {
                    size: 14px;
                    weight: 600;
                };
            }
            i {
                position: absolute;
                font-size: 45px;
                left: 30px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
}

/*================================================
Products Area CSS
=================================================*/
.single-products-box {
    background-color: #f5f9ff;
    margin-bottom: 30px;

    .products-image {
        border-radius: 5px;
        overflow: hidden;

        a {
            display: block;
            border-radius: 5px;

            img {
                border-radius: 5px;
                transition: $transition;
            }
        }
    }
    .products-content {
        border-radius: 0 0 5px 5px;
        padding: 20px;
        position: relative;

        h3 {
            margin-bottom: 15px;
            font: {
                size: 19px;
                weight: 800;
            };
            a {
                display: inline-block;
            }
        }
        .price {
            color: $main-color;
            font: {
                size: 16px;
                weight: 700;
            };
            span {
                display: inline-block;
                margin-left: 3px;

                &.old {
                    text-decoration: line-through;
                    color: #999999;
                    font-weight: 500;
                    margin-left: 3px;
                }
            }
        }
        .add-to-cart-btn {
            position: absolute;
            right: 20px;
            bottom: 20px;
            display: inline-block;
            text-transform: uppercase;
            opacity: 0.67;
            font: {
                size: 14px;
                weight: 700;
            };
            &:hover {
                opacity: 1;
            }
        }
    }
    &:hover {
        .products-image {    
            a {
                img {
                    transform: scale(1.2) rotate(5deg);
                }
            }
        }
    }
}
.shorting-menu {
    text-align: center;
    margin-bottom: 30px;

    .filter {
        display: inline-block;
        background-color: transparent;
        border: none;
        padding: 0;
        position: relative;
        color: $optional-color;
        transition: $transition;
        padding-bottom: 3px;
        margin: {
            left: 10px;
            right: 10px;
        };
        font: {
            size: 15px;
            weight: 700;
        };
        &::before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 2px;
            background-color: $main-color;
            transform: scaleX(0);
            transition: $transition;
        }
        &.mixitup-control-active, &:hover {
            color: $main-color;

            &::before {
                transform: scaleX(1);
            }
        }
    }
}

/*================================================
Products Details Area CSS
=================================================*/
.products-details-image {
    img {
        width: 100%;
    }
    .slick-slider {
        position: relative;
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    .slick-dots {
        padding: 0;
        text-align: center;
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        margin: {
            left: -5px;
            right: -5px;
            top: 0;
            bottom: 0;
        };
        li {
            display: block;
            flex: 0 0 33.333333%;
            max-width: 33.333333%;
            position: relative;
            padding: {
                left: 5px;
                right: 5px;
                top: 10px;
            };
            button {
                padding: 0;
                outline: 0;
                display: block;
                border: none;
                margin: 0;
                cursor: pointer;
            }
            img {
                transition: $transition;
            }
            &:hover, &.slick-active {
                img {
                    opacity: .4;
                }
            }
        }
    }
    .slick-thumbs {
        display: none;
    }
    .row {
        margin: {
            left: -8px;
            right: -8px;
            top: -15px;
        };
        .col-lg-6 {
            padding: {
                left: 8px;
                right: 8px;
            };
            .single-products-details-image {
                margin-top: 15px;
            }
        }
    }
    .single-products-details-image {
        margin-top: 30px;

        &:first-child {
            margin-top: 0;
        }
    }
}
.products-details-desc {
    h3 {
        margin-bottom: 12px;
        font: {
            size: 20px;
            weight: 800;
        };
    }
    .price {
        margin-bottom: 15px;
        color: $main-color;
        font: {
            size: $font-size;
            weight: 600;
        }
        .old-price {
            text-decoration: line-through;
            color: #828893;
            font-weight: normal;
            margin-left: 5px;
        }
    }
    .products-review {
        margin-bottom: 15px;

        .rating {
            display: inline-block;
            padding-right: 5px;
            font-size: 16.5px;

            i {
                color: #ffba0a;
                display: inline-block;
            }
        }
        .rating-count {
            display: inline-block;
            color: $black-color;
            border-bottom: 1px solid $black-color;
            line-height: initial;
            position: relative;
            top: -2px;
            font-weight: 600;

            &:hover {
                color: $main-color;
                border-color: $main-color;
            }
        }
    }
    .products-info {
        list-style-type: none;
        padding-left: 0;
        margin: {
            top: 15px;
            bottom: 20px;
        };
        li {
            color: $black-color;
            margin-bottom: 8px;
            font: {
                size: $font-size;
            };
            &:last-child {
                margin-bottom: 0;
            }
            span {
                color: $optional-color;
            }
            a {
                display: inline-block;
                font-weight: 600;
                color: $black-color;

                &:hover {
                    color: $main-color;
                }
            }
        }
    }
    .products-info-btn {
        margin-bottom: 25px;

        a {
            text-transform: uppercase;
            display: inline-block;
            position: relative;
            padding-left: 22px;
            line-height: initial;
            font: {
                size: 14px;
                weight: 600;
            };
            &::before {
                content: '';
                position: absolute;
                left: 0;
                bottom: -2px;
                width: 0;
                height: 1.5px;
                background: $main-color;
                transition: $transition;
            }
            i {
                font-size: 18px;
                position: absolute;
                left: 0;
                top: 0;
            }
            &:hover {
                color: $main-color;

                &::before {
                    width: 100%;
                }
            }
            &:not(:first-child) {
                margin-left: 15px;
            }
        }
    }
    .products-add-to-cart {
        .input-counter {
            max-width: 120px;
            min-width: 120px;
            margin-right: 10px;
            text-align: center;
            display: inline-block;
            position: relative;    

            span {
                position: absolute;
                top: 0;
                background-color: transparent;
                cursor: pointer;
                color: #d0d0d0;
                width: 40px;
                height: 100%;
                line-height: 50px;
                transition: $transition;
                font-size: 22px;

                &.minus-btn {
                    left: 0;
                }
                &.plus-btn {
                    right: 0;
                }
                &:hover {
                    color: $main-color;
                }
            }
            input {
                height: 50px;
                color: $black-color;
                outline: 0;
                display: block;
                border: none;
                background-color: #f8f8f8;
                text-align: center;
                width: 100%;
                font: {
                    size: 17px;
                    weight: 600;
                }
                &::placeholder {
                    color: $black-color;
                }
            }
        }
        .default-btn {
            top: -2px;
        }
    }
    .buy-checkbox-btn {
        margin-top: 20px;

        input {
            display: none;
        }
        .cbx {
            margin: auto;
            -webkit-user-select: none;
            user-select: none;
            cursor: pointer;

            span {
                display: inline-block;
                vertical-align: middle;
                transform: translate3d(0, 0, 0);

                &:first-child {
                    position: relative;
                    width: 18px;
                    height: 18px;
                    border-radius: 3px;
                    transform: scale(1);
                    vertical-align: middle;
                    border: 1px solid #ebebeb;
                    transition: all 0.2s ease;
                    transition: $transition;

                    svg {
                        position: absolute;
                        top: 3px;
                        left: 2px;
                        fill: none;
                        stroke: $white-color;
                        stroke-width: 2;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                        stroke-dasharray: 16px;
                        stroke-dashoffset: 16px;
                        transition: all 0.3s ease;
                        transition-delay: 0.1s;
                        transform: translate3d(0, 0, 0);
                        transition: $transition;
                    }
                    &:before {
                        content: "";
                        width: 100%;
                        height: 100%;
                        background: $main-color;
                        display: block;
                        transform: scale(0);
                        opacity: 1;
                        border-radius: 50%;
                        transition: $transition;
                    }
                }
                &:last-child {
                    padding-left: 4px;
                    color: $black-color;
                    position: relative;
                    top: -1px;
                }
            }
            &:hover {
                span {
                    &:first-child {
                        border-color: $main-color;
                    }
                }
            }
        }
        .inp-cbx:checked + .cbx span:first-child {
            background: $main-color;
            border-color: $main-color;
            animation: wave 0.4s ease;
        }
        .inp-cbx:checked + .cbx span:first-child svg {
            stroke-dashoffset: 0;
        }
        .inp-cbx:checked + .cbx span:first-child:before {
            transform: scale(3.5);
            opacity: 0;
            transition: all 0.6s ease;
        }
        .item {
            &:not(:first-child) {
                margin-top: 20px;
            }
        }
        .default-btn {
            display: block;
            text-align: center;
        }
    }
    .products-details-accordion {
        margin-top: 25px;

        .accordion-item {
            display: block;
            box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.13) !important;
            background: $white-color;
            margin-bottom: 15px;
            border-radius: 0;
            border: none;
    
            &:last-child {
                margin-bottom: 0;
            }
            .accordion-header {
                padding: 0;
                border-bottom: none;
                border-radius: 0 !important;
                background-color: $white-color;
    
                .accordion-button {
                    background-color: transparent;
                    padding: 12px 35px 10px 15px;
                    color: $black-color;
                    position: relative;
                    border-radius: 0;
                    box-shadow: unset;
                    display: block;
                    border: {
                        left: 3px solid $black-color;
                        bottom: 1px solid #eeeeee;
                    };
                    font: {
                        size: 16px;
                        weight: 600;
                    };
                    i {
                        top: 50%;
                        right: 15px;
                        font-size: 14px;
                        position: absolute;
                        transform: translateY(-50%);
    
                        &::before {
                            content: "\f10a";
                        }
                    }
                    &.collapsed {
                        border: {
                            bottom: {
                                color: transparent;
                            };
                        };
                        i {
                            &::before {
                                content: "\f109";
                            }
                        }
                    }
                    &::after {
                        display: none;
                    }
                }
            }
            p {
                font-size: 14.5px;
            }
            ul, ol {
                padding-left: 0;
                list-style-type: none;
                margin-bottom: 0;

                li {
                    margin-bottom: 10px;
                    position: relative;
                    padding-left: 13px;
                    color: $optional-color;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 8px;
                        left: 0;
                        width: 6px;
                        height: 6px;
                        background: $main-color;
                        border-radius: 50%;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            .table {
                margin-bottom: 0;

                &.table-striped {
                    tbody {
                        tr {
                            td {
                                border-top: none;
                                vertical-align: middle;
                                white-space: nowrap;
                                padding: 10px 15px;
                                color: $black-color;
                                font-size: 14.5px;

                                &:first-child {
                                    font: {
                                        weight: 500;
                                    };
                                }
                            }
                        }
                    }
                }
                &.table-bordered {
                    tbody {
                        tr {
                            td {
                                border-color: #eeeeee;
                                color: $optional-color;
                                vertical-align: middle;
                                white-space: nowrap;
                                padding: 10px 15px;
                                font-size: 14.5px;
                                line-height: 1.7;
                                border-bottom: 0;

                                &:first-child {
                                    color: $black-color;
                                    font: {
                                        weight: 500;
                                    };
                                }
                            }
                        }
                    }
                }
            }
            h3 {
                margin-bottom: 12px;
                font: {
                    size: 20px;
                    weight: 800;
                };
            }
            .products-review-form {
                margin-top: 10px;

                .review-title {
                    position: relative;

                    .rating {
                        display: inline-block;

                        .bx.bxs-star {
                            color: #ffba0a;
                        }
                        i {
                            color: #ebebeb;
                            font-size: 16px;
                            margin-right: -1px;
                        }
                    }
                    p {
                        margin-bottom: 0;
                        display: inline-block;
                        padding-left: 5px;
                        line-height: initial;
                        position: relative;
                        top: -2px;
                    }
                    .default-btn {
                        position: absolute;
                        right: 0;
                        bottom: 0;
                    }
                }
                .review-comments {
                    margin-top: 35px;
                    
                    .review-item {
                        margin-top: 30px;
                        position: relative;
                        border-top: 1px dashed #eeeeee;
                        padding-top: 30px;

                        .rating {
                            .bx.bxs-star {
                                color: #ffba0a;
                            }
                            i {
                                font-size: 17px;
                                color: #ebebeb;
                                margin-right: -1px;
                            }
                        }
                        h3 {
                            font-size: 16px;
                            margin: {
                                top: 8px;
                                bottom: 8px;
                            };
                        }
                        span {
                            margin-bottom: 10px;
                            font-size: $font-size;
                            display: block;

                            strong {
                                font-weight: 500;
                            }
                        }
                        p {
                            margin-bottom: 0;
                        }
                    }
                }
                .review-form {
                    margin-top: 30px;

                    h3 {
                        margin-bottom: 20px;
                    }
                    form {
                        .form-group {
                            margin-bottom: 15px;
                        }
                    }
                }
            }
        }
    }
}
.related-products {
    margin-top: 70px;
}

/*================================================
Cart Area CSS
=================================================*/
.cart-table {
    table {
        margin-bottom: 0;

        thead {
            tr {
                border-top: none;
                
                th {
                    border-bottom-width: 0px;
                    vertical-align: middle;
                    padding: 0 0 15px;
                    text-transform: uppercase;
                    border: none;
                    white-space: nowrap;
                    font: {
                        size: $font-size;
                        weight: 800;
                    };
                }
            }
        }
        tbody {
            tr {
                td {
                    vertical-align: middle;
                    color: $optional-color;
                    white-space: nowrap;
                    padding: {
                        left: 0;
                        right: 0;
                        top: 15px;
                        bottom: 15px;
                    };
                    border: {
                        color: #eaedff;
                        left: none;
                        right: none;
                    };
                    &.product-thumbnail {
                        a {
                            display: inline-block;

                            img {
                                width: 100px;
                            }
                        }
                    }
                    &.product-name {
                        a {
                            display: inline-block;
                            font: {
                                size: 15px;
                                weight: 700;
                            };
                        }
                        ul {
                            padding-left: 0;
                            list-style-type: none;
                            margin: {
                                top: 10px;
                                bottom: 0;
                            };
                            li {
                                margin-bottom: 6px;
                                font-size: 13.5px;

                                span {
                                    display: inline;
                                    color: $black-color;
                                }
                                &:last-child {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                    &.product-subtotal {
                        overflow: hidden;

                        .subtotal-amount {
                            color: $black-color;
                            font-weight: 600;
                        }
                        .remove {
                            color: red;
                            float: right;
                            display: block;
                            position: relative;
                            top: -1px;
                            font-size: 18px;
                        }
                    }
                    &.product-quantity {
                        .input-counter {
                            max-width: 130px;
                            min-width: 130px;
                            text-align: center;
                            display: inline-block;
                            position: relative;    
                
                            span {
                                position: absolute;
                                top: 0;
                                background-color: transparent;
                                cursor: pointer;
                                color: #d0d0d0;
                                width: 40px;
                                height: 100%;
                                line-height: 50px;
                                transition: $transition;
                                font-size: 22px;
                
                                &.minus-btn {
                                    left: 0;
                                }
                                &.plus-btn {
                                    right: 0;
                                }
                                &:hover {
                                    color: $main-color;
                                }
                            }
                            input {
                                height: 45px;
                                color: $black-color;
                                outline: 0;
                                display: block;
                                border: none;
                                background-color: #f8f8f8;
                                text-align: center;
                                width: 100%;
                                font: {
                                    size: 17px;
                                    weight: 600;
                                }
                                &::placeholder {
                                    color: $black-color;
                                }
                            }
                        }
                    }
                    .unit-amount {
                        color: $black-color;
                        font-weight: 600;
                    }
                }
            }
        }
    }
}
.cart-buttons {
    margin-top: 30px;
}
.cart-totals {
    background: $white-color;
    padding: 40px;
    max-width: 500px;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    margin: {
        left: auto;
        top: 50px;
        right: auto;
    };
    h3 {
        margin-bottom: 20px;
        font: {
            size: 22px;
            weight: 800;
        };
    }
    ul {
        padding-left: 0;
        margin: 0 0 25px;
        list-style-type: none;
        
        li {
            border: 1px solid #eaedff;
            padding: 10px 15px;
            color: $black-color;
            overflow: hidden;
            font: {
                weight: 700;
            };
            &:first-child {
                border: {
                    bottom: none;
                }
            }
            &:last-child {
                font: {
                    size: 18px;
                };
                border: {
                    top: none;
                };
                span {
                    color: $black-color;
                }
            }
            span {
                float: right;
                font: {
                    weight: normal;
                };
            }
        }
    }
}

/*================================================
Checkout Area CSS
=================================================*/
.user-actions {
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    background: $white-color;
    padding: 15px 20px;
    border-top: 3px solid $main-color;
    position: relative;
    margin-bottom: 40px;

    i {
        color: $main-color;
        margin-right: 2px;
        font-size: 20px;
        position: relative;
        top: 3px;
    }
    span {
        display: inline-block;
        color: $main-color;
        font: {
            weight: 600;
        };
        a {
            display: inline-block;
            color: $black-color;

            &:hover, &:focus {
                color: $main-color;
            }
        }
    }
}
.billing-details {
    .title {
        margin-bottom: 30px;
        position: relative;
        padding-bottom: 10px;
        border-bottom: 1px solid #eeeeee;
        font: {
            size: 20px;
            weight: 800;
        };
        &::before {
            content: '';
            position: absolute;
            background: $main-color;
            bottom: -1px;
            left: 0;
            width: 50px;
            height: 1px;
        }
    }
    .form-group {
        margin-bottom: 25px;

        label {
            display: block;
            margin-bottom: 8px;
            text-transform: uppercase;
            font: {
                weight: 600;
                size: 14px;
            };
            .required {
                color: red;
            }
        }
        .nice-select {
            float: unset;
            line-height: 48px;
            color: $black-color;
            border: none;
            background-color: #f5f5f5 !important;
            font: {
                weight: normal;
                size: $font-size;
            };
            padding: {
                top: 0;
                bottom: 0;
            };
            .list {
                background-color: $white-color;
                box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
                border-radius: 0;
                margin-top: 0;
                width: 100%;
                padding: {
                    top: 10px;
                    bottom: 10px;
                };
                .option {
                    transition: $transition;
                    padding: {
                        left: 20px;
                        right: 20px;
                    }
                    &:hover {
                        background-color: $main-color !important;
                        color: $white-color;
                    }
                    &.selected {
                        background-color: transparent;
                        font-weight: 600;
                    }
                }
            }
            &:after {
                right: 20px;
            }
        }
    }
    .form-check {
        margin-bottom: 20px;

        .form-check-label {
            color: $black-color;
        }
        label {
            position: relative;
            left: -3px;
            top: 1px;
            font: {
                weight: 500;
            }
        }
    }
    .col-lg-12 {
        &:last-child {
            .form-group {
                margin-bottom: 0;
            }
        }
    }
}
.order-details {
    .title {
        margin-bottom: 30px;
        position: relative;
        padding-bottom: 10px;
        border-bottom: 1px solid #eeeeee;
        font: {
            size: 20px;
            weight: 800;
        };
        &::before {
            content: '';
            position: absolute;
            background: $main-color;
            bottom: -1px;
            left: 0;
            width: 50px;
            height: 1px;
        }
    }
    .order-table {
        table {
            margin-bottom: 0;
    
            thead {
                tr {
                    th {
                        border-bottom-width: 0;
                        vertical-align: middle;
                        border-color: #eaedff;
                        padding: 12px 20px 10px;
                        font: {
                            weight: 800;
                            size: 16px;
                        };
                    }
                }
            }
            tbody {
                tr {
                    td {
                        vertical-align: middle;
                        border-color: #eaedff;
                        font-size: $font-size;
                        padding: {
                            left: 20px;
                            right: 20px;
                            top: 15px;
                            bottom: 13px;
                        };
                        &.product-name {
                            a {
                                display: inline-block;
                                font: {
                                    weight: 600;
                                };
                            }
                        }
                        &.order-subtotal, &.order-shipping , &.total-price {
                            span {
                                color: $black-color;
                                font-weight: 700;
                            }
                        }
                        &.shipping-price, &.order-subtotal-price, &.product-subtotal {
                            font-weight: 700;
                            color: $black-color;
                        }
                    }
                }
            }
        }
    }
    .payment-box {
        background-color: $white-color;
        box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
        margin-top: 30px;
        padding: 30px;
        
        .payment-method {
            p {
                [type="radio"] {
                    &:checked, &:not(:checked) {
                        display: none;
                    }
                }
                [type="radio"] {
                    &:checked, &:not(:checked) {
                        + label {
                            padding-left: 27px;
                            cursor: pointer;
                            display: block;
                            color: $black-color;
                            position: relative;
                            margin-bottom: 8px;
                            font: {
                                weight: 700;
                            }
                            &::before {
                                content: '';
                                position: absolute;
                                left: 0;
                                top: 5px;
                                width: 18px;
                                height: 18px;
                                border: 1px solid #dddddd;
                                border-radius: 50%;
                                background: $white-color;
                            }
                            &::after {
                                content: '';
                                width: 12px;
                                height: 12px;
                                background: $main-color;
                                position: absolute;
                                top: 8px;
                                left: 3px;
                                border-radius: 50%;
                                transition: $transition;
                            }
                        }
                    }
                }
                [type="radio"] {
                    &:not(:checked) {
                        + label {
                            &::after {
                                opacity: 0;
                                visibility: hidden;
                                transform: scale(0);
                            }
                        }
                    }
                }
                [type="radio"] {
                    &:checked {
                        + label {
                            &::after {
                                opacity: 1;
                                visibility: visible;
                                transform: scale(1);
                            }
                        }
                    }
                }
            }
        }
        .default-btn {
            margin-top: 20px;
            box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, .2);
            display: block;
            text-align: center;
        }
    }
}

/*================================================
Blog Area CSS
=================================================*/
.single-blog-post {
    margin-bottom: 30px;
    position: relative;
    z-index: 1;
    border-radius: 5px;

    .post-image {
        border-radius: 5px;

        a {
            display: block;

            img {
                border-radius: 5px;
            }
        }
    }
    .post-content {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: auto;
        padding: 30px;
        
        .date {
            display: block;
            color: $white-color;
            margin-bottom: 10px;
        }
        h3 {
            margin-bottom: 0;
            color: $white-color;
            max-width: 400px;
            line-height: 1.5;
            font: {
                size: 20px;
                weight: 800;
            };
            a {
                color: $white-color;
                display: inline-block;

                &:hover {
                    color: $main-color;
                }
            }
        }
    }
    &::before {
        content: '';
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
        border-radius: 5px;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 35%;
    }
}
.small-blog-post {
    margin-bottom: 30px;

    .single-small-blog-post {
        margin-bottom: 23px;

        .post-image {
            border-radius: 5px;
            border: 1px solid #cedcfa;
            padding: 5px;
    
            a {
                display: block;
                
                img {
                    border-radius: 5px;
                    max-width: 130px;
                }
            }
        }
        .post-content {
            padding-left: 20px;

            .date {
                display: block;
                margin-bottom: 8px;
                color: #606060;

                i {
                    color: $main-color;
                }
            }
            h3 {
                margin-bottom: 0;
                line-height: 1.4;
                font: {
                    size: 18px;
                    weight: 800;
                };
                a {
                    display: inline-block;
                }
            }
        }
    }
}
.single-blog-post-item {
    position: relative;
    margin-bottom: 30px;

    .post-image {
        overflow: hidden;
        position: relative;
        border-radius: 5px 5px 0 0;
        
        a {
            display: block;
            border-radius: 5px 5px 0 0;

            img {
                border-radius: 5px 5px 0 0;
                transition: $transition;
            }
        }
        .date {
            position: absolute;
            right: -100%;
            top: 20px;
            background-color: $white-color;
            color: $black-color;
            padding: 5px 10px 6px;
            transition: $transition;
            visibility: hidden;
            font: {
                style: italic;
                size: 15px;
                weight: 600;
            };
        }
    }
    .post-content {
        margin-top: 20px;

        .category {
            display: block;
            margin-bottom: 10px;
            text-transform: uppercase;
            color: $main-color;
            font: {
                weight: 600;
                size: 14px;
            };
        }
        h3 {
            margin-bottom: 0;
            line-height: 1.5;
            font: {
                size: 20px;
                weight: 800;
            };
            a {
                display: inline-block;
            }
        }
        .details-btn {
            margin-top: 13px;
            display: inline-block;
            position: relative;
            color: $black-color;
            text-transform: uppercase;
            font: {
                size: 13px;
                weight: 600;
            };
            &::before {
                width: 100%;
                height: 1px;
                background-color: $optional-color;
                content: '';
                position: absolute;
                transition: $transition;
                left: 0;
                bottom: 0;
            }
            &:hover {
                color: $main-color;

                &::before {
                    width: 0;
                }
            }
        }
    }
    &:hover {
        .post-image {
            img {
                transform: scale(1.07) rotate(2deg);
            }
            .date {
                visibility: visible;
                right: 0;
            }
        }
    }
}

/*================================================
Blog Details Area CSS
=================================================*/
.blog-details-desc {
    .article-image {
        img {
            width: 100%;
        }
    }
    .article-content {
        margin-top: 30px;

        .entry-meta {
            ul {
                padding-left: 0;
                margin-bottom: 0;
                list-style-type: none;
    
                li {
                    margin-right: 20px;
                    position: relative;
                    display: inline-block;
                    border-right: 1px solid #eeeeee;
                    font: {
                        weight: 700;
                        size: 15px;
                    };
                    padding: {
                        right: 20px;
                        left: 45px;
                    };
                    i {
                        font-size: 32px;
                        color: #cfcfcf;
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                    span {
                        display: block;
                        color: $main-color;
                        text-transform: uppercase;
                        margin-bottom: 2px;
                        font: {
                            size: 14px;
                            weight: 600;
                        };
                    }
                    a {
                        display: inline-block;
                        font: {
                            size: 15px;
                            weight: 700;
                        };
                    }
                    &:last-child {
                        padding-right: 0;
                        margin-right: 0;
                        border-right: none;
                    }
                }
            }
        }
        h3 {
            margin: {
                bottom: 17px;
                top: 25px;
            };
            font: {
                size: 22px;
                weight: 700;
            };
        }
        .wp-block-gallery {
            &.columns-3 {
                padding-left: 0;
                list-style-type: none;
                display: flex;
                flex-wrap: wrap;
                margin: {
                    right: -10px;
                    left: -10px;
                    bottom: 30px;
                    top: 30px;
                }
                li {
                    flex: 0 0 33.3333%;
                    max-width: 33.3333%;
                    padding: {
                        right: 10px;
                        left: 10px;
                    }
                    figure {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .features-list {
            padding-left: 0;
            list-style-type: none;
            margin: {
                top: 25px;
                bottom: 30px;
            };
            li {
                margin-bottom: 15px;
                position: relative;
                padding-left: 26px;
                color: $optional-color;

                i {
                    color: $main-color;
                    display: inline-block;
                    font-size: 20px;
                    position: absolute;
                    left: 0;
                    top: 1px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .article-footer {
        display: flex;
        flex-wrap: wrap;
        border-top: 1px solid #eeeeee;
        padding-top: 30px;
        margin: {
            top: 30px;
        };
        .article-tags {
            flex: 0 0 50%;
            max-width: 50%;
            color: $optional-color;
            
            span {
                display: inline-block;
                color: $black-color;
                margin-right: 3px;
                position: relative;
                top: 3px;
                font: {
                    size: 20px;
                };
            }
            a {
                display: inline-block;
                font: {
                    weight: 600;
                };
            }
        }
        .article-share {
            flex: 0 0 50%;
            max-width: 50%;
            
            .social {
                padding-left: 0;
                list-style-type: none;
                text-align: end;
                margin: {
                    bottom: 0;
                };
                li {
                    display: inline-block;

                    span {
                        display: inline-block;
                        margin-right: 3px;
                        font-weight: 600;
                        position: relative;
                        top: -2px;
                    }
                    a {
                        display: block;
                        width: 32px;
                        height: 32px;
                        line-height: 32px;
                        border-radius: 50%;
                        background-color: $main-color;
                        color: $white-color;
                        border: 1px solid $main-color;
                        text-align: center;
                        font-size: 17px;
                        margin-left: 5px;
        
                        &:hover, &:focus {
                            color: $main-color;
                            background-color: transparent;
                        }
                        &.facebook {
                            background-color: #3b5998;
                            border-color: #3b5998;
                            color: $white-color;

                            &:hover, &:focus {
                                color: #3b5998;
                                background-color: transparent;
                            }
                        }
                        &.twitter {
                            background-color: #1da1f2;
                            border-color: #1da1f2;
                            color: $white-color;

                            &:hover, &:focus {
                                color: #1da1f2;
                                background-color: transparent;
                            }
                        }
                        &.linkedin {
                            background-color: #007bb5;
                            border-color: #007bb5;
                            color: $white-color;

                            &:hover, &:focus {
                                color: #007bb5;
                                background-color: transparent;
                            }
                        }
                        &.instagram {
                            background-color: #c13584;
                            border-color: #c13584;
                            color: $white-color;

                            &:hover, &:focus {
                                color: #c13584;
                                background-color: transparent;
                            }
                        }
                    }
                }
            }
        }
    }
    blockquote, .blockquote {
        overflow: hidden;
        background-color: #fafafa;
        padding: 50px !important;
        position: relative;
        text-align: center;
        z-index: 1;
        margin: {
            bottom: 20px;
            top: 20px;
        };
        p {
            color: $black-color;
            line-height: 1.6;
            margin-bottom: 0;
            font: {
                style: italic;
                weight: 700;
                size: 22px !important;
            };
        }
        cite {
            display: none;
        }
        &::before {
            color: #efefef;
            position: absolute;
            animation: fade-up 1.5s infinite linear;
            left: 50px;
            top: -50px;
            z-index: -1;
            content: "\f112";
            font: {
                family: Flaticon;
                style: normal;
                size: 135px;
            };
        }
        &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 3px;
            background-color: $main-color;
            margin: {
                top: 20px;
                bottom: 20px;
            };
        }
    }
}
.qovid-post-navigation {
    display: flex;
    flex-wrap: wrap;
    margin: {
        top: 30px;
    };
    padding: {
        top: 30px;
        bottom: 30px;
    };
    border: {
        top: 1px solid #eeeeee;
        bottom: 1px solid #eeeeee;
    };
}
.prev-link-wrapper {
    flex: 0 0 50%;
    max-width: 50%;
    padding: {
        right: 15px;
    };
    a {
        display: flex;
        align-items: center;

        &:hover {
            .image-prev {
                &::after {
                    opacity: 1;
                    visibility: visible;
                }
                .post-nav-title {
                    opacity: 1;
                    visibility: visible;
                }
            }
            .prev-link-info-wrapper {
                color: $main-color;
            }
        }
    }
    .image-prev {
        display: inline-block;
        min-width: 100px;
        width: 100px;
        border-radius: 5px;
        overflow: hidden;
        vertical-align: top;
        margin-right: 20px;
        position: relative;
        transition: $transition;

        img {
            border-radius: 5px;
        }
        &::after {
            display: block;
            content: '';
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 5px;
            opacity: 0;
            background-color: $main-color;
            visibility: hidden;
            transition: $transition;
        }
        .post-nav-title {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            margin: 0 auto;
            text-align: center;
            text-transform: uppercase;
            z-index: 2;
            color: $white-color;
            opacity: 0;
            visibility: hidden;
            transition: $transition;
            font: {
                size: 16px;
                weight: 500;
            };
        }
    }
    .prev-link-info-wrapper {
        color: $black-color;
        transition: $transition;
    }
    .prev-title {
        display: inline-block;
        font: {
            weight: 700;
            size: 16px;
        };
    }
    .meta-wrapper {
        display: block;
        color: $optional-color;
        text-transform: capitalize;
        margin-top: 5px;
        font: {
            weight: 500;
            size: 14px;
        };
    }
}
.next-link-wrapper {
    flex: 0 0 50%;
    max-width: 50%;
    text-align: end;
    padding: {
        left: 15px;
    };
    a {
        display: flex;
        align-items: center;

        &:hover {
            .image-next {
                &::after {
                    opacity: 1;
                    visibility: visible;
                }
                .post-nav-title {
                    opacity: 1;
                    visibility: visible;
                }
            }
            .next-link-info-wrapper {
                color: $main-color;
            }
        }
    }
    .image-next {
        display: inline-block;
        min-width: 100px;
        width: 100px;
        border-radius: 5px;
        overflow: hidden;
        vertical-align: top;
        margin-left: 20px;
        position: relative;
        transition: $transition;

        img {
            border-radius: 5px;
        }
        &::after {
            display: block;
            content: '';
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 5px;
            opacity: 0;
            background-color: $main-color;
            visibility: hidden;
            transition: $transition;
        }
        .post-nav-title {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            margin: 0 auto;
            text-align: center;
            text-transform: uppercase;
            z-index: 2;
            color: $white-color;
            opacity: 0;
            visibility: hidden;
            transition: $transition;
            font: {
                size: 16px;
                weight: 500;
            };
        }
    }
    .next-link-info-wrapper {
        transition: $transition;
        color: $black-color;
    }
    .next-title {
        display: inline-block;
        font: {
            weight: 700;
            size: 16px;
        };
    }
    .meta-wrapper {
        display: block;
        color: $optional-color;
        text-transform: capitalize;
        margin-top: 5px;
        font: {
            weight: 500;
            size: 14px;
        };
    }
}
.comments-area {
    margin-top: 30px;

    .comments-title {
        line-height: initial;
        margin: {
            bottom: 30px;
        };
        font: {
            size: 22px;
            weight: 700;
        };
    }
    ol, ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    .comment-list {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    .children {
        margin-left: 20px;
    }
    .comment-body {
        border-bottom: 1px dashed #eeeeee;
        padding-left: 65px;
        color: $black-color;
        font-size: 14px;
        margin-bottom: 20px;
        padding-bottom: 20px;

        .reply {
            margin-top: 15px;

            a {
                border: 1px dashed #ded9d9;
                color: $black-color;
                display: inline-block;
                padding: 5px 20px;
                border-radius: 30px;
                text-transform: uppercase;
                font: {
                    size: 13px;
                    weight: 600;
                };
                &:hover {
                    color: $white-color;
                    background-color: $main-color;
                    border-color: $main-color;
                }
            }
        }
    }
    .comment-author {
        font-size: 16px;
        margin-bottom: 0.3em;
        position: relative;
        z-index: 2;

        .avatar {
            height: 50px;
            left: -65px;
            position: absolute;
            width: 50px;
        }
        .fn {
            font: {
                weight: 700;
            };
        }
        .says {
            display: none;
        }
    }
    .comment-metadata {
        margin-bottom: .8em;
        color: $optional-color;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        font: {
            size: 13px;
            weight: 400;
        };
        a {
            display: inline-block;
            color: $optional-color;

            &:hover {
                color: $main-color;
            }
        }
    }
    .comment-respond {
        margin-top: 30px;

        .comment-reply-title {
            margin-bottom: 0;
            font: {
                size: 22px;
                weight: 700;
            };
            #cancel-comment-reply-link {
                display: inline-block;
            }
        }
        .comment-form {
            overflow: hidden;
        }
        .comment-notes {
            margin: {
                bottom: 20px;
                top: 10px;
            };
            .required {
                color: red;
            }
        }
        .comment-form-comment {
            float: left;
            width: 100%;
        }
        label {
            display: none;
        }
        input[type="date"], input[type="time"], input[type="datetime-local"], input[type="week"], input[type="month"], input[type="text"], input[type="email"], input[type="url"], input[type="password"], input[type="search"], input[type="tel"], input[type="number"], textarea {
            display: block;
            width: 100%;
            background-color: #f4f4f4;
            border: none;
            padding: 0 0 0 15px;
            height: 50px;
            outline: 0;
            border-radius: 3px;
            color: $black-color;
            font: {
                weight: 500;
                size: $font-size;
            };
            &::placeholder {
                transition: $transition;
                color: $optional-color;
            }
            &:focus {
                &::placeholder {
                    color: transparent;
                }
            }
        }
        textarea {
            height: auto !important;
            padding-top: 15px;
        }
        .comment-form-author {
            float: left;
            width: 50%;
            padding-right: 10px;
            margin-bottom: 20px;
        }
        .comment-form-email {
            float: left;
            width: 50%;
            padding-left: 12px;
            margin-bottom: 20px;
        }
        .comment-form-url {
            float: left;
            width: 100%;
            margin-bottom: 20px;
        }
        .comment-form-cookies-consent {
            width: 100%;
            float: left;
            position: relative;
            padding-left: 20px;
            margin: {
                bottom: 20px;
                top: 15px;
            };
            input {
                position: absolute;
                left: 0;
                top: 4px;
            }
            label {
                display: inline-block;
                margin: 0;
                color: $optional-color;
                position: relative;
                top: -3.2px;
                font: {
                    weight: normal;
                    size: 16px;
                };
            }
        }
        .form-submit {
            float: left;
            width: 100%;

            input {
                background: $main-color;
                border: none;
                color: $white-color;
                padding: 10px 30px 9px;
                display: inline-block;
                cursor: pointer;
                text-transform: uppercase;
                transition: $transition;
                border-radius: 0;
                font: {
                    weight: 600;
                    size: 14.5px;
                };
                &:hover, &:focus {
                    color: $white-color;
                    background: $black-color;
                }
            }
        }
    }
}

/*================================================
Subscribe Area CSS
=================================================*/
.subscribe-area {
    border-bottom: 1.5px solid #1b1b60;
    padding-bottom: 45px;
    margin-bottom: 45px;

    h2 {
        margin-bottom: 0;
        color: $white-color;
        font: {
            size: 28px;
            weight: 800;
        };
    }
    .newsletter-form {
        position: relative;
        padding-left: 30px;

        .input-newsletter {
            height: 50px;
            display: block;
            width: 100%;
            border: none;
            border-radius: 0;
            border-radius: 5px;
            font: {
                size: 14px;
                weight: 600;
                family: $font-family;
            };
            padding: {
                top: 0;
                right: 0;
                bottom: 0;
                left: 15px;
            };
            &::placeholder {
                color: #999999;
                transition: $transition;
            }
            &:focus {
                &::placeholder {
                    color: transparent;
                }
            }
        }
        button {
            position: absolute;
            right: 0;
            top: 0;
            height: 50px;
            border: none;
            border-radius: 0 5px 5px 0;
            background-color: $main-color;
            display: inline-block;
            text-transform: uppercase;
            color: $white-color;
            transition: $transition;
            padding: {
                left: 25px;
                right: 55px;
            };
            font: {
                size: 14px;
                weight: 600;
            };
            i {
                position: absolute;
                right: 25px;
                top: 50%;
                transform: translateY(-50%);
                font-size: 20px;
                transition: $transition;
            }
            &:hover {
                i {
                    right: 20px;
                }
            }
        }
        #validator-newsletter {
            color: $white-color !important;
            position: absolute;
            left: 30px;
            bottom: -32px;

            &.validation-danger {
                color: red !important;
            }
        }
    }
}

/*================================================
Page Title Area CSS
=================================================*/
.page-title-area {
    position: relative;
    z-index: 1;
    background: {
        color: $main-color;
        image: url(../../assets/img/funfacts-bg.jpg);
        position: center center;
        repeat: no-repeat;
        size: cover
    };
    padding: {
        top: 150px;
        bottom: 130px;
    };
    &::before {
        width: 100%;
        height: 100%;
        background-color: $black-color;
        opacity: 0.75;
        content: '';
        position: absolute;
        z-index: -1;
        left: 0;
        top: 0;
    }
}
.page-title-content {
    h2 {
        margin-bottom: 0;
        color: $white-color;
        font: {
            size: 45px;
            weight: 800;
        };
    }
    ul {
        padding-left: 0;
        list-style-type: none;
        margin: {
            top: 15px;
            bottom: 0;
        };
        li {
            display: inline-block;
            margin-right: 24px;
            color: $white-color;
            position: relative;
            font: {
                size: 16px;
                weight: 600;
            };
            a {
                display: inline-block;
                color: $white-color;

            }
            &::before {
                content: '';
                position: absolute;
                right: -12px;
                top: 7px;
                width: 1px;
                height: 12px;
                background-color: $white-color;
            }
            &:last-child {
                margin-right: 0;

                &::before {
                    display: none;
                }
            }
        }
    }
}

/*================================================
Pagination CSS
=================================================*/
.pagination-area {
    position: relative;
    z-index: 1;
    margin: {
        top: 30px;
    };
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        height: 1px;
        background-color: #eeeeee;
        z-index: -1;
    }
    .page-numbers {
        color: $black-color;
        display: inline-block;
        line-height: 1;
        background-color: $white-color;
        font: {
            weight: 700;
            size: 20px;
        };
        margin: {
            left: -1px;
            right: -1px;
            top: -10px;
            bottom: -10px;
        };
        padding: {
            left: 8px;
            right: 8px;
        };
        &:hover, &:focus, &.current {
            color: $main-color;
        }
        &.prev {
            padding-right: 0;
        }
        &.next {
            padding-left: 0;
        }
        &.prev, &.next {
            font-size: 28px;
            position: relative;
            top: 5px;
            margin: {
                left: -3px;
                right: -3px;
            };
        }
    }
}

/*================================================
Widget Sidebar CSS
=================================================*/
.widget-area {
    .widget {
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;
        }
        .widget-title {
            margin-bottom: 20px;
            position: relative;
            padding-bottom: 8px;
            border-bottom: 1px solid #eeeeee;
            font: {
                size: 19px;
                weight: 800;
            };
            &::before {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 50px;
                height: 1px;
                bottom: -1px;
                background-color: $main-color;
            }
        }
    }
    .widget_search {
        form {
            position: relative;
            
            .screen-reader-text {
                display: none;
            }
            label {
                display: block;
                margin-bottom: 0;
            }
            .search-field {
                height: 50px;
                color: $black-color;
                background-color: #f2f4f5;
                display: block;
                width: 100%;
                border-radius: 5px;
                padding: 0 0 0 15px;
                border: none;
                transition: $transition;
                font: {
                    size: $font-size;
                    weight: 600;
                };
                &::placeholder {
                    color: $optional-color;
                    transition: $transition;
                }
                &:focus {
                    &::placeholder {
                        color: transparent;
                    }
                }
            }
            button {
                border: none;
                background-color: $main-color;
                color: $white-color;
                height: 40px;
                width: 40px;
                position: absolute;
                right: 5px;
                transition: $transition;
                border-radius: 5px;
                top: 5px;
                font-size: 20px;

                &:hover, &:focus {
                    background-color: $black-color;
                    color: $white-color;
                }
            }
        }
    }
    .widget_qovid_posts_thumb {
        position: relative;
        overflow: hidden;
        
        .item {
            overflow: hidden;
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }
            .thumb {
                float: left;
                height: 80px;
                overflow: hidden;
                display: block;
                position: relative;
                width: 80px;
                margin-right: 15px;
                z-index: 1;

                .fullimage {
                    width: 80px;
                    height: 80px;
                    display: inline-block;
                    border-radius: 5px;
                    background: {
                        size: cover !important;
                        repeat: no-repeat;
                        position: center center !important;
                    };
                    &.bg1 {
                        background-image: url(../../assets/img/blog/img7.jpg);
                    }
                    &.bg2 {
                        background-image: url(../../assets/img/blog/img8.jpg);
                    }
                    &.bg3 {
                        background-image: url(../../assets/img/blog/img5.jpg);
                    }
                }
                &::before, &::after {
                    transition: all .8s cubic-bezier(.2, 1, .22, 1);
                    content: '';
                    background-color: $white-color;
                    position: absolute;
                    z-index: 1;
                    top: 50%;
                    left: 50%;
                    opacity: 0;
                    transform: translate(-50%, -50%);
                }
                &::before {
                    width: 40px;
                    height: 1px;
                    left: 100%;
                }
                &::after {
                    height: 40px;
                    width: 1px;
                    top: 0;
                }
            }
            .info {
                overflow: hidden;
                margin-top: 5px;

                span {
                    display: block;
                    color: $optional-color;
                    text-transform: uppercase;
                    margin: {
                        top: -2px;
                        bottom: 5px;
                    };
                    font: {
                        size: 12px;
                    };
                }
                .title {
                    margin-bottom: 0;
                    line-height: 1.4;
                    font: {
                        size: 15.5px;
                        weight: 700;
                    };
                    a {
                        display: inline-block;
                    }
                }
            }
            &:hover {
                .thumb {
                    &::before, &::after {
                        opacity: 1;
                        top: 50%;
                        left: 50%;
                    }
                }
            }
        }
    }
    .widget_categories {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                position: relative;
                margin-bottom: 12px;
                overflow: hidden;
                color: $black-color;
                padding-left: 17px;
                font: {
                    weight: 700;
                    size: 15px;
                };
                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    background: $main-color;
                    height: 8px;
                    width: 8px;
                    content: '';
                    border-radius: 50%;
                    left: 0;
                    top: 8px;
                    position: absolute;
                }
                a {
                    color: $black-color;
                    display: inline-block;

                    &:hover {
                        color: $main-color;
                    }
                }
                .post-count {
                    color: $optional-color;
                    font-size: 14px;
                    display: inline-block;
                    position: relative;
                    top: 1px;
                }
            }
        }
    }
    .widget_tag_cloud {
        .widget-title {
            margin-bottom: 12px;
        }
    }
    .tagcloud {
        a {
            display: inline-block;
            background-color: #f6f6f6;
            color: $black-color;
            padding: 7px 15px;
            border: none;
            border-radius: 3px;
            font: {
                weight: 700;
                size: 14px !important;
            };
            margin: {
                top: 8px;
                right: 4px;
            };
            &:hover, &:focus {
                color: $white-color;
                background-color: $main-color;
            }
        }
    }
    .widget_instagram {
        ul {
            padding-left: 0;
            list-style-type: none;
            flex-wrap: wrap;
            display: flex;
            margin: {
                bottom: -10px;
                left: -5px;
                right: -5px;
            };
            li {
                flex: 0 0 33.3333333333%;
                max-width: 33.3333333333%;
                padding: {
                    bottom: 10px;
                    right: 5px;
                    left: 5px;
                };
                a {
                    position: relative;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        background-color: $main-color;
                        transition: $transition;
                        opacity: 0;
                        visibility: hidden;
                    }
                    &:hover {
                        &::before {
                            opacity: .70;
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }
    .widget_contact {
        position: relative;
        z-index: 1;
        border-radius: 5px;
        height: 400px;
        text-align: center;
        background: {
            image: url(../../assets/img/main-banner1.jpg);
            position: center center;
            size: cover;
            repeat: no-repeat;
        };
        &::before {
            width: 100%;
            height: 100%;
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            border-radius: 5px;
            background-color: $main-color;
            opacity: .40;
            z-index: -1;
        }
        .text {
            position: absolute;
            left: 0;
            bottom: 25px;
            right: 0;

            .icon {
                width: 65px;
                height: 65px;
                border: 2px dashed $main-color;
                position: relative;
                border-radius: 50%;
                font-size: 35px;
                transition: $transition;
                background-color: $white-color;
                color: $main-color;
                margin: {
                    left: auto;
                    right: auto;
                    bottom: 17px;
                };
                i {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
            span {
                display: block;
                color: $white-color;
                margin-bottom: 8px;
                font: {
                    size: 16px;
                    weight: 700;
                };
            }
            a {
                display: inline-block;
                color: $white-color;
                font: {
                    weight: 700;
                    size: 25px;
                };
            }
        }
    }
}

/*================================================
Coming Soon Area CSS
=================================================*/
.coming-soon-area {
    height: 100vh;
    position: relative;
    z-index: 1;
    background: #f6f7fb;
    background: {
        image: url(../../assets/img/coming-soon-bg.jpg);
        position: top center;
        size: cover;
        repeat: no-repeat;
    };
}
.coming-soon-content {
    max-width: 700px;
    background: $white-color;
    border-radius: 5px;
    overflow: hidden;
    text-align: center;
    padding: 40px 60px;
    box-shadow: 1px 5px 24px 0 rgba(68,102,242,.05);
    margin: {
        left: auto;
        right: auto;
    };
    .logo {
        display: inline-block;
    }
    h2 {
        font: {
            size: 40px;
            weight: 800;
        };
        margin: {
            top: 30px;
            bottom: 0;
        };
    }
    #timer {
        margin-top: 40px;

        div {
            background-color: #333333;
            color: $white-color;
            width: 100px;
            height: 105px;
            border-radius: 5px;
            font: {
                size: 40px;
                weight: 700;
            };
            margin: {
                left: 10px;
                right: 10px;
            };
            span {
                display: block;
                margin-top: -2px;
                font: {
                    size: 16px;
                    weight: 500;
                };
            }
        }
    }
    form {
        position: relative;
        max-width: 500px;
        margin: {
            left: auto;
            right: auto;
            top: 50px;
        };
        .form-group {
            margin-bottom: 25px;
            width: 100%;
            position: relative;

            .label-title {
                margin-bottom: 0;
                position: absolute;
                display: block;
                left: 0;
                top: 0;
                pointer-events: none;
                width: 100%;
                height: 100%;
                color: $black-color;

                i {
                    position: absolute;
                    left: 0;
                    transition: $transition;
                    top: 9px;
                    font: {
                        size: 22px;
                    };
                }
                &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 0;
                    height: 2px;
                    transition: $transition;
                    background: $main-color;
                }
            }
            .input-newsletter {
                border-radius: 0;
                border: none;
                border-bottom: 2px solid #eeeeee;
                padding: 0 0 0 32px;
                color: $black-color;
                height: 45px;
                display: block;
                width: 100%;
                transition: $transition;
                font: {
                    size: 16px;
                    weight: 400;
                };
                &::placeholder {
                    color: #A1A1A1;
                    transition: $transition;
                }
                &:focus {                    
                    &::placeholder {
                        color: transparent;
                    }
                }
            }
        }
        .default-btn {
            border-radius: 0;
        }
        .validation-danger {
            margin-top: 15px;
            color: red;
        }
        .validation-success {
            margin-top: 15px;
        }
        p {
            margin: {
                bottom: 0;
                top: 20px;
            };
        }
    }
}

/*================================================
Error 404 Area CSS
=================================================*/
.error-404-area {
    z-index: 1;
    background: #f8f8f8;
    position: relative;
    height: 100vh;

    .notfound {
        position: relative;
        max-width: 520px;
        z-index: 1;
        text-align: center;
        margin: {
            left: auto;
            right: auto;
        };
        .notfound-bg {
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            z-index: -1;

            div {
                width: 100%;
                background: $white-color;
                border-radius: 90px;
                height: 125px;

                &:nth-child(1) {
                    box-shadow: 5px 5px 0 0 #f3f3f3;
                }
                &:nth-child(2) {
                    transform: scale(1.3);
                    box-shadow: 5px 5px 0 0 #f3f3f3;
                    position: relative;
                    z-index: 10;
                }
                &:nth-child(3) {
                    box-shadow: 5px 5px 0 0 #f3f3f3;
                    position: relative;
                    z-index: 90;
                }
            }
        }
        h1 {
            background: url(../../assets/img/main-banner1.jpg) no-repeat;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-size: cover;
            background-position: center;
            text-transform: uppercase;
            font: {
                size: 100px;
                weight: 800;
            };
            margin: {
                top: 0;
                bottom: 15px;
            };
        }
        h3 {
            margin-bottom: 12px;
            font: {
                weight: 700;
                size: 22px;
            };
        }
        p {
            color: #6084a4;
            margin-bottom: 20px;
            line-height: 1.7;
        }
    }
}

/*================================================
Contact Area CSS
=================================================*/
.contact-info {
    h3 {
        margin-bottom: 15px;
        font: {
            size: 22px;
            weight: 800;
        };
    }
    p {
        a {
            display: inline;
            font-weight: 500;
        }
    }
    .contact-list {
        padding-left: 0;
        margin-bottom: 25px;
        list-style-type: none;

        li {
            margin-bottom: 12px;
            position: relative;
            padding-left: 25px;

            i {
                position: absolute;
                left: 0;
                top: 2px;
                color: $main-color;
                font-size: 20px;
            }
            a {
                display: inline-block;
                font-weight: 600;
                position: relative;
                
                &:hover {
                    &::before {
                        width: 100%;
                    }
                }
                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 0;
                    height: 1px;
                    transition: $transition;
                    background-color: $main-color;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .opening-hours {
        padding-left: 0;
        margin-bottom: 25px;
        list-style-type: none;

        li {
            margin-bottom: 10px;
            
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .social {
        padding-left: 0;
        list-style-type: none;
        margin: {
            bottom: 0;
        };
        li {
            display: inline-block;
            margin-right: 5px;

            &:last-child {
                margin-right: 0;
            }
            a {
                width: 30px;
                height: 30px;
                text-align: center;
                display: block;
                color: $main-color;
                background-color: #f4f4f4;
                font-size: 18px;
                position: relative;
                border-radius: 2px;

                i {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    right: 0;
                    transform: translateY(-49%);
                }
                &:hover {
                    background-color: $main-color;
                    color: $white-color;
                }
            }
        }
    }
}
.contact-form {
    h3 {
        margin-bottom: 15px;
        font: {
            size: 22px;
            weight: 800;
        };
    }
    form {
        .form-group {
            label {
                display: block;
                margin-bottom: 10px;
                text-transform: uppercase;
                font: {
                    size: 14px;
                    weight: 600;
                };
                span {
                    color: red;
                }
            }
        }
        .with-errors {
            ul {
                padding-left: 0;
                list-style-type: none;
                margin: {
                    top: 10px;
                    bottom: 0;
                };
                li {
                    color: red;
                    font-size: 15px;
                }
            }
        }
        #msgSubmit {
            margin-bottom: 0;

            &.text-danger, &.text-success {
                margin-top: 15px;
            }
        }
        .default-btn {
            margin-top: 5px;
        }
    }
}
#map {
    width: 100%;
    height: 500px;
}
#live-map-tracker {
    position: relative;
    overflow: hidden;

    .link-btn {
        display: inline-block;
        text-transform: uppercase;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        text-decoration: underline;
        top: 12.5px;
        text-align: center;
        font: {
            size: 15px;
            weight: 700;
        };
    }
    iframe {
        width: 100%;
        height: 100vh;
        border: none;
    }
}

/*================================================
Footer Area CSS
=================================================*/
.footer-area {
    background-color: #130947;
    padding-top: 100px;
    position: relative;
    z-index: 1;
}
.single-footer-widget {
    margin-bottom: 30px;

    .logo {
        display: inline-block;
        margin-bottom: 15px;
    }
    h3 {
        margin-bottom: 30px;
        position: relative;
        color: $white-color;
        padding-bottom: 8px;
        font: {
            size: 20px;
            weight: 800;
        };
        &::before {
            width: 60px;
            height: 2px;
            background-color: $main-color;
            position: absolute;
            bottom: 0;
            left: 0;
            content: '';
        }
    }
    p {
        color: #f8f8f8;
    }
    .footer-quick-links {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            margin-bottom: 12px;

            a {
                color: #f8f8f8;
                display: inline-block;
                font: {
                    weight: 600;
                };
                &:hover {
                    color: $main-color;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .social-link {
        padding-left: 0;
        list-style-type: none;
        margin: {
            top: 20px;
            bottom: 0;
        };
        li {
            display: inline-block;
            margin-right: 5px;

            &:last-child {
                margin-right: 0;
            }
            a {
                width: 30px;
                height: 30px;
                text-align: center;
                color: $black-color;
                background-color: #f8f8f8;
                font-size: 18px;
                position: relative;
                border-radius: 2px;

                i {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    right: 0;
                    transform: translateY(-48%);
                }
                &:hover {
                    background-color: $main-color;
                    color: $white-color;
                }
            }
        }
    }
    .footer-contact-info {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            margin-bottom: 12px;
            color: #f8f8f8;

            span {
                font-weight: 600;
                color: $white-color;
            }
            a {
                color: #f8f8f8;
                display: inline-block;
                
                &:hover {
                    color: $main-color;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .footer-working-days {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            margin-bottom: 12px;
            color: #f8f8f8;

            span {
                font-weight: 600;
                color: $white-color;
            }
            a {
                color: #f8f8f8;
                display: inline-block;
                
                &:hover {
                    color: $main-color;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
.footer-bottom-area {
    margin-top: 70px;
    background-color: #07002b;
    padding: {
        top: 25px;
        bottom: 25px;
    };
    p {
        color: #f8f8f8;

        i {
            position: relative;
            top: 1.5px;
        }
        a {
            color: $white-color;
            font-weight: 600;
        }
    }
    ul {
        text-align: end;
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            color: #f8f8f8;
            position: relative;
            display: inline-block;
            margin-right: 20px;

            &:last-child {
                margin-right: 0;

                &::before {
                    display: none;
                }
            }
            &::before {
                content: '';
                position: absolute;
                right: -12px;
                top: 6px;
                width: 1px;
                height: 14px;
                background-color: #f8f8f8;
            }
            a {
                display: block;
                color: #f8f8f8;

                &:hover {
                    color: $main-color;
                }
            }
        }
    }
}
.footer-shape1 {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
    
    img {
        animation: movebounce 7s linear infinite;
    }
}
.footer-shape2 {
    position: absolute;
    right: 8%;
    bottom: 18%;
    z-index: -1;

    img {
        animation: movescale 10s linear infinite;
    }
}
.footer-shape3 {
    position: absolute;
    right: 5%;
    top: 7%;
    z-index: -1;

    img {
        animation: {
            name: rotateme;
            duration: 40s;
            iteration-count: infinite;
            timing-function: linear;
        };
    }
}
@keyframes movebounce {
    0% {
        transform: translateY(0px); 
    }
    50% {
        transform: translateY(20px); 
    }
    100% {
        transform: translateY(0px); 
    } 
}
@keyframes moveleftbounce {
    0% {
        transform: translateX(0px); 
    }
    50% {
        transform: translateX(-20px); 
    }
    100% {
        transform: translateX(0px); 
    } 
}
@keyframes rotateme {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
@keyframes movescale {
    0% {
        transform: scale(.9);
    }
    50% {
        transform: scale(.8);
    }
    100% {
        transform: scale(.9);
    } 
}

/*================================================
Go Top CSS
=================================================*/
.go-top {
	position: fixed;
	cursor: pointer;
    bottom: -42px;
	right: 20px;
	color: $white-color;
	background-color: $main-color;
	z-index: 4;
	width: 42px;
	text-align: center;
	height: 42px;
    line-height: 42px;
    opacity: 0;
    visibility: hidden;
    font-size: 28px;
    transition: $transition;
    border-radius: 5px 5px 0 0;
    
    i {
        position: absolute;
        left: 0;
        right: 0;
        top: 55%;
        transform: translateY(-55%);
        transition: .3s;
    }
    &.active {
        bottom: 0;
        opacity: 1;
        visibility: visible;
    }
    &:hover {
        i {
            margin-top: -3px;
        }
    }
}
.live-data-sidebar {
    position: fixed;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 99998;

    .inner-content {
        background-color: $white-color;
        box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.13) !important;
        border-radius: 5px 0 0 5px;
        padding: 25px;
        transition: $transition;

        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;
    
            li {
                margin-bottom: 12px;
                color: $main-color;
                font-weight: 600;
    
                strong {
                    color: $black-color;
                    font-weight: 700;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .default-btn {
            border-radius: 0;
            margin-top: 18px;
            display: block;
            width: 100%;
            padding: {
                top: 10px;
                bottom: 10px;
                left: 30px;
                right: 30px;
            };
        }
        .date {
            font-size: 13px;
            margin: {
                top: 13px;
                bottom: 0;
            };
        }
    }
    .live-data-btn {
        position: absolute;
        right: 0;
        bottom: -55px;
        box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.13) !important;
        background-color: $white-color;
        cursor: pointer;
        opacity: 0;
        visibility: hidden;
        padding: 10px 15px;
        font: {
            size: $font-size;
            weight: 700;
        };
    }
}