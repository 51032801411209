/* You can add global styles to this file, and also import other style files */

::placeholder {
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 20px !important;
    color: #515C63 !important;
}

.form-control {
    background-color: #fff;
    border-radius: 7px;
    margin-top: 15px;
}

a.social-button {
    border-radius: 50% !important;
    background-color: #5cc1c9 !important;
    color: white !important;
}

div.contact-info {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #515C63;
    text-align: left
}

.section-title h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    text-align: center;
    color: #4BBAC4;
}

section.contact-area .section-title h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    text-align: center;
    color: #fff;
}

.btn-contact {
    padding: 10px 30px !important;
    background-color: #0E818B;
    border-radius: 7px;
    border: 0;
    width: 160px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: #FFFFFF;
}

.go-top {
    background-color: #4BBAC4;
}

section.contact-area {
    background-color: #4BBAC4;
}

.single-footer-widget h3::before {
    background-color: transparent !important;
}

.symptoms-area {
    background-color: #E9F6F6 !important;
    background-image: none;
    padding-top: 20px;
}

div.symptoms-content > h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 20px;
    color: #4BBAC4;
}

.symptoms-content ul li span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: #515C63;
}

.symptoms-content ul li span:before {
    background-color: #4BBAC4;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


.swal2-container .select-wrapper{
    display: none !important;
}

.nice-select {
    display: none !important;
}