$media: screen;
$feature_min: min-width;
$feature_max: max-width;
$value_one: 576px;
$value_two: 767px;
$value_three: 768px;
$value_four: 991px;
$value_five: 992px;
$value_six: 1199px;
$value_seven: 1550px;

@media only #{$media} and ($feature_max : $value_two) {

    body {
        font-size: 14px;
    }
    .ptb-100 {
        padding: {
            top: 60px;
            bottom: 60px;
        };
    }
    .pt-100 {
        padding-top: 60px;
    }
    .pb-100 {
        padding-bottom: 60px;
    }
    .ptb-70 {
        padding: {
            top: 30px;
            bottom: 30px;
        };
    }
    .pt-70 {
        padding-top: 30px;
    }
    .pb-70 {
        padding-bottom: 30px;
    }
    .container {
        max-width: 100%;
    }
    .default-btn {
        padding: 11px 15px 10px 40px;
        font-size: 12px;

        i {
            left: 15px;
        }
    }
    .optional-btn {
        padding: 11px 15px 10px 40px;
        font-size: 12px;

        i {
            left: 15px;
        }
    }
    .section-title {
        margin-bottom: 40px;

        .sub-title {
            font-size: 13px;
            margin-bottom: 8px;
        }
        h2 {
            font-size: 22px;
            line-height: 1.4;
        }
    }
    .form-control {
        font-size: 14px;
    }

    .top-header {
        text-align: center;
    }
    .top-header-contact-info {
        text-align: center;
        margin-top: 3px;

        li {
            margin-top: 7px;

            &:first-child {
                margin-top: 0;
            }
        }
    }

    .header-area {
        position: relative;
        z-index: 999;
    }

    .main-banner {
        padding-top: 105px;
    }
    .main-banner-content {
        text-align: center;

        .sub-title {
            font-size: 14px;
            display: inline-block;
        }
        h1 {
            font-size: 28px;
            margin-bottom: 12px;
            line-height: 1.3;
        }
        .btn-box {
            margin-top: 25px;

            .default-btn {
                margin-right: 5px;
            }
            .optional-btn {
                margin-left: 5px;
            }
        }
    }
    .main-banner-image {
        margin-top: 30px;
    }
    .home-slides {
        &.owl-theme {
            .owl-nav {
                [class*=owl-] {
                    left: 15px;
                    font-size: 25px;
                    opacity: 1;
                    visibility: visible;
                    top: 60%;
                    transform: translateY(-60%);

                    &.owl-next {
                        left: auto;
                        right: 15px;
                    }
                }
            }
            &:hover {
                .owl-nav {
                    [class*=owl-] {
                        left: 15px;
                        
                        &.owl-next {
                            left: auto;
                            right: 15px;
                        }
                    }
                }
            }
        }
    }
    .banner-section {
        padding: {
            top: 170px;
            bottom: 0;
        };
        .main-banner-content {
            max-width: 100%;
            margin-left: 0;

            h1 {
                font-size: 28px;
            }
            p {
                max-width: 100%;
            }
        }
        .main-banner-image {
            text-align: center;
        }
    }
    .hero-banner {
        padding: {
            top: 140px;
            bottom: 60px;
        };
    }
    .hero-banner-content {
        h1 {
            font-size: 25px;
        }
        .banner-box-list {
            margin-bottom: 25px;
            padding: {
                left: 0;
                right: 0;
            };
            .single-banner-box {
                margin-bottom: 15px;
                padding: 15px 15px 15px 85px;

                .icon {
                    left: 15px;

                    img {
                        width: 50px;
                    }
                }
                h3 {
                    font-size: 21px;
                }
                p {
                    font-size: 14px;
                }
            }
        }
    }
    .banner-wrapper {
        height: auto;
        padding: {
            top: 180px;
            bottom: 100px;
        };
    }
    .banner-wrapper-content {
        text-align: center;
        max-width: 100%;
        margin: {
            top: 0;
            left: 0;
        };
        h1 {
            font-size: 30px;
        }
        p {
            font-size: 15px;
        }
        .btn-box {
            margin-top: 25px;

            .default-btn {
                margin-right: 5px;
            }
            .optional-btn {
                margin-left: 5px;
            }
        }
    }
    .banner-area {
        height: auto;
        padding: {
            top: 140px;
            bottom: 90px;
        };
    }
    .banner-image {
        text-align: center;
        height: auto;
        margin: {
            top: 40px;
            left: auto;
            right: auto;
        };
        img {
            position: relative;
            display: inline-block;
            width: 100%;

            &:nth-child(1) {
                left: 0;
                top: 0;
            }
            &:nth-child(2) {
                display: none;
            }
            &:nth-child(3) {
                display: none;
            }
        }
    }
    .home-slides-two {
        .main-banner {
            height: auto;
            padding: {
                top: 120px;
                bottom: 150px;
            };
            .main-banner-content {
                .sub-title {
                    margin-bottom: 0;
                }
            }
        }
        &.owl-theme {
            .owl-nav {
                [class*=owl-] {
                    left: 15px;
                    font-size: 25px;
                    opacity: 1;
                    visibility: visible;
                    top: auto;
                    transform: translateY(0);
                    bottom: 15px;

                    &.owl-next {
                        left: auto;
                        right: 15px;
                    }
                }
            }
            &:hover {
                .owl-nav {
                    [class*=owl-] {
                        left: 15px;
                        
                        &.owl-next {
                            left: auto;
                            right: 15px;
                        }
                    }
                }
            }
        }
    }

    .about-image {
        text-align: center;
        padding: {
            right: 0;
            bottom: 0;
        };
        img {
            &:nth-child(2) {
                position: relative;
                margin-top: 30px;
            }
        }
    }
    .about-content {
        padding-left: 0;
        margin-top: 30px;
        text-align: center;

        .sub-title {
            font-size: 13px;
            margin-bottom: 8px;
        }
        h2 {
            font-size: 21px;
            line-height: 1.3;
        }
        blockquote {
            text-align: start !important;
            border-width: 2px;
            padding: 15px;

            p {
                font-size: 15px;
            }
        }
    }
    .about-section {
        .col-lg-6 {
            &:nth-child(1) {
                order: 2;
            }
            &:nth-child(2) {
                order: 1;
            }
        }
    }
    .about-img {
        margin-top: 30px;
    }
    .about-shape1 {
        display: none;
    }
    .about-inner-area {
        margin-top: 35px;
        
        .image {
            order: 1;
        }
        .content {
            order: 2;
        }
    }
    .about-content-two {
        text-align: center;
        margin-top: 30px;
        padding: {
            left: 0;
            right: 0 !important;
        };
        .sub-title {
            font-size: 13px;
            margin-bottom: 8px;
        }
        h2 {
            font-size: 21px;
            line-height: 1.3;
        }
        h6 {
            font-size: 14px;
        }
        .features-text {
            margin-top: 20px;

            h5 {
                margin-bottom: 12px;
                line-height: 1.5;
                padding: {
                    top: 0;
                    left: 0;
                };
                i {
                    display: none;
                }
            }
        }
        .features-list {
            li {
                flex: 0 0 100%;
                max-width: 100%;
                margin-top: 12px;

                span {
                    font-size: 14px;
                    padding-left: 0;

                    i {
                        display: none;
                    }
                }
            }
        }
    }

    .cta-inner-area {
        margin-top: 0;
        border-radius: 5px;
        text-align: center;
        padding: {
            left: 15px;
            right: 15px;
            top: 40px;
        };
        .row {
            .col-lg-8 {
                order: 2;

                .col-lg-5 {
                    order: 2;
                }
                .col-lg-7 {
                    order: 1;
                }
            }
            .col-lg-4 {
                order: 1;
            }
        }
        img {
            top: 0;
            margin: {
                bottom: 0;
                top: 25px;
            };
        }
        h3 {
            font-size: 18px;
            line-height: 1.3;
            margin: {
                top: 25px;
                bottom: 10px;
            };
        }
        .cta-btn {
            a {
                padding: 12px 20px 12px 65px;
                font-size: 18px;

                i {
                    font-size: 30px;
                    left: 20px;
                }
                span {
                    font-size: 12px;
                }
            }
        }
    }

    .how-to-spread-area {
        &.bg-white-color {
            padding-top: 60px;

            &::before {
                display: none;
            }
        }
    }
    .single-spread-virus-box {
        padding: 15px;

        h3 {
            margin-bottom: 8px;
            font-size: 15px;
        }
        .read-more-btn {
            font-size: 13px;
        }
    }
    .overview-box {
        margin-top: 35px;

        &:nth-child(2) {
            margin-top: 0;
        }
        .overview-content {
            flex: 0 0 100%;
            order: 2;
            max-width: 100%;

            .content {
                padding-left: 0;
                text-align: center;
                margin-top: 30px;

                .icon {
                    margin-bottom: 20px;
                }
                h3 {
                    font-size: 14px;
                }
            }
        }
        .overview-image {
            flex: 0 0 100%;
            order: 1;
            max-width: 100%;

            .image {
                margin-right: 0;

                .main-image {
                    padding: 0;
                }
                .shape {
                    display: none;
                }
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    .spread-shape2, .spread-shape1 {
        display: none;
    }

    .search-overlay {
        display: none !important;
    }

    .outbreak-content {
        .outbreak-box-list {
            margin-bottom: 25px;
            padding: {
                left: 0;
                right: 0;
            };
            .single-outbreak-box {
                margin-bottom: 15px;
                padding: 15px 15px 15px 85px;

                .icon {
                    left: 15px;

                    img {
                        width: 50px;
                    }
                }
                h3 {
                    font-size: 21px;
                }
                p {
                    font-size: 14px;
                }
            }
        }
    }

    .symptoms-area {
        background-image: unset !important;

        .row {
            .col-lg-6 {
                &:nth-child(1) {
                    order: 2;
                }
                &:nth-child(2) {
                    order: 1;
                }
            }
        }
    }
    .symptoms-content {
        text-align: center;
        padding-left: 0;

        .sub-title {
            font-size: 13px;
            margin-bottom: 8px;
        }
        h2 {
            font-size: 21px;
        }
        ul {
            text-align: start;

            li {
                flex: 0 0 100%;
                max-width: 100%;

                span {
                    font-size: 13px;
                    margin-top: 10px;
                }
            }
        }
    }
    .symptoms-image {
        left: 0;
        text-align: center;
        margin-top: 30px;
    }

    .funfacts-list {
        .single-funfacts-box {
            margin: {
                bottom: 0 !important;
                top: 30px;
            };
        }
    }
    .single-funfacts-box {
        padding: {
            top: 25px;
            bottom: 25px;
        };
        h3 {
            font-size: 30px;
        }
        p {
            font-size: 12.5px;
        }
    }
    .funfacts-info {
        text-align: center;
    }
    .funfacts-section {
        .funfacts-image {
            margin-left: 0;
            max-width: 100%;
        }
        .funfacts-list {
            max-width: 100%;

            .row {
                .col-lg-6 {
                    &:nth-child(4) {
                        .single-funfacts-box {
                            margin-top: 30px;
                        }
                    }
                }
                .col-lg-12 {
                    margin-top: 0;
                }
            }
        }
    }
    .funfacts-shape1 {
        display: none;
    }

    .symptoms-section {
        &.bg-white-color {
            padding-top: 60px;

            &::before {
                display: none;
            }
        }
    }
    .symptoms-box-list {
        .box-item {
            padding: {
                left: 0;
                right: 0;
            };
            h3 {
                font-size: 17px;
            }
            p {
                font-size: 14px;
            }
            &:nth-child(1) {
                left: 0;
            }
            &:nth-child(3) {
                left: 0;
            }
        }
    }
    .symptoms-img {
        left: 0;
        margin: {
            top: 30px;
            bottom: 30px;
        };
        img {
            max-width: 100%;
        }
    }
    .row {
        .col-lg-4 {
            &:nth-child(3) {
                .symptoms-box-list {
                    .box-item {
                        &:nth-child(1) {
                            left: 0;
                        }
                        &:nth-child(3) {
                            left: 0;
                        }
                    }
                }
            }
        }
    }
    .symptoms-shape1, .symptoms-shape2 {
        display: none;
    }

    .how-to-protect-content {
        .content {
            margin: {
                top: 5px;
                bottom: 25px;
                left: 5px;
            };
            &::before {
                left: -5px;
                top: -5px;
            }
            .text {
                padding: 20px;
                text-align: center;

                .icon {
                    width: 65px;
                    height: 65px;
                    position: relative;
                    display: inline-block;
                    left: 0;
                    top: 0;
                    transform: unset;
                    margin-bottom: 18px;
                    font-size: 30px;
                }
                h3 {
                    font-size: 16px;
                }
            }
        }
    }
    .how-to-protect-image {
        height: auto;
        margin: {
            left: 0;
            top: 30px;
        };
        .main-image {
            top: 0;
            transform: unset;
            border-width: 5px;
            right: 0;
            text-align: center;
            margin: {
                left: auto;
                right: auto;
            };
            &:nth-child(1) {
                position: relative;
            }
        }
        .how-to-protect-shape {
            display: none;
        }
    }
    .how-to-protect-content-list {
        max-width: 100%;
        margin-left: 0;

        .content {
            .text {
                text-align: center;
                padding: 0;

                .icon {
                    width: 65px;
                    height: 65px;
                    position: relative;
                    display: inline-block;
                    left: 0;
                    top: 0;
                    transform: unset;
                    margin-bottom: 18px;
                    font-size: 30px;
                }
                h3 {
                    font-size: 15px;
                }
            }
        }
    }
    .how-to-protect-img {
        margin-top: 30px;
    }

    .faq-image {
        height: auto;
        background-image: unset !important;
        margin-bottom: 30px;

        img {
            display: inline-block;
        }
        .video-btn {
            width: 65px;
            height: 65px;
            font-size: 25px;
            line-height: 65px;
        }
    }
    .faq-accordion {
        .accordion-item {
            .accordion-header {
                .accordion-button {
                    border-left-width: 2px;
                    font-size: 14px;

                    i {
                        font-size: 12px;
                    }
                }
            }
            .collapse {
                .accordion-body {
                    position: relative;
                }
            }
        }
    }
    .faq-accordion-content {
        .accordion-item {
            .accordion-header {
                .accordion-button {
                    border-left-width: 2px;
                    font-size: 14px;

                    i {
                        font-size: 12px;
                    }
                }
            }
            .collapse {
                .accordion-body {
                    position: relative;
                }
            }
        }
    }
    .faq-shape1, .faq-shape2 {
        display: none;
    }

    .spread-virus-content {
        text-align: center;
        max-width: 100%;
        margin: {
            left: 0;
            bottom: 0;
        };
        .sub-title {
            font-size: 13px;
        }
        h2 {
            font-size: 21px;
        }
        ul {
            li {
                padding-left: 0;

                .icon {
                    position: relative;
                    left: 0;
                    right: 0;
                    margin-bottom: 15px;
                    display: inline-block;
                }
                h3 {
                    font-size: 17px;
                }
                p {
                    max-width: 100%;
                    font-size: 14px;
                }
            }
        }
    }
    .spread-virus-image {
        left: 0;
        padding-top: 30px;
    }

    .prevention-content {
        .title {
            padding: {
                left: 15px;
                right: 15px;
            };
            h3 {
                font-size: 16px;
            }
            &::before {
                bottom: -8px;
            }
        }
        .content-list {
            padding: 45px 20px 20px;

            .content {
                padding-left: 0;
                text-align: center;

                .icon {
                    position: relative;
                    top: 0;
                    left: 0;
                    display: inline-block;
                    margin-bottom: 20px;
                }
                h3 {
                    font-size: 16px;
                }
                p {
                    font-size: 14px;
                }
            }
        }
    }
    .prevention-shape1, .prevention-shape2, .prevention-shape3, .prevention-shape4 {
        display: none;
    }

    .single-doctors-box {
        text-align: center;
        margin: {
            top: 0;
            left: 0;
        };
        &::before {
            display: none;
        }
        .content {
            padding: 20px;

            h3 {
                font-size: 15px;
            }
            span {
                font-size: 13px;
            }
        }
    }

    .emergency-contact-inner {
        padding: 25px;
        border-radius: 5px;
        text-align: center;

        .btn-box {
            .default-btn {
                margin-bottom: 15px;
            }
        }
        .contact-cta {
            a {
                padding: 12px 20px 12px 65px;
                font-size: 18px;

                i {
                    font-size: 30px;
                    left: 20px;
                }
                span {
                    font-size: 12px;
                }
            }
        }
    }

    .faq-section {
        .faq-accordion {
            margin-bottom: 15px;
        }
        &::before {
            height: 10%;
        }
    }
    .faq-img {
        max-width: 100%;
        margin-top: 20px;

        .video-btn {
            width: 65px;
            height: 65px;
            font-size: 30px;
            line-height: 65px;
        }
    }

    .shorting-menu {
        margin-bottom: 20px;

        .filter {
            padding-bottom: 2px;
            font-size: 14px;
            margin: {
                left: 8px;
                right: 8px;
                bottom: 10px;
            };
        }
    }
    .single-products-box {
        .products-content {
            h3 {
                font-size: 15px;
            }
            .price {
                font-size: 14px;
            }
            .add-to-cart-btn {
                font-size: 12px;
            }
        }
    }

    .single-blog-post {
        .post-content {
            padding: 15px;

            h3 {
                max-width: 100%;
                font-size: 15px;
            }
        }
        &::before {
            height: 50%;
        }
    }
    .small-blog-post {
        .single-small-blog-post {
            .post-image {
                padding: 2px;

                a {
                    img {
                        max-width: 100px;
                    }
                }
            }
            .post-content {
                padding-left: 15px;

                .date {
                    font-size: 13px;
                }
                h3 {
                    font-size: 14px;
                }
            }
        }
    }
    .single-blog-post-item {
        .post-content {
            .category {
                font-size: 13px;
            }
            h3 {
                font-size: 14px;
            }
            .details-btn {
                font-size: 12px;
            }
        }
    }

    .widget-area {
        margin-top: 40px;

        .widget {
            .widget-title {
                font-size: 14px;
            }
        }
        .widget_qovid_posts_thumb {
            .item .info .title {
                font-size: 14px;
            }
        }
        .widget_categories {
            ul {
                li {
                    font-size: 13px;
                }
            }
        }
        .tagcloud {
            a {
                font-size: 12.5px !important;
            }
        }
    }

    .blog-details-desc {
        .article-content {
            .entry-meta {
                margin-bottom: -15px;

                ul {
                    li {
                        font-size: 13px;
                        margin: {
                            bottom: 15px;
                            right: 15px;
                        };
                        padding: {
                            right: 15px;
                            left: 0;
                        };
                        i {
                            display: none;
                        }
                        span {
                            font-size: 13px;
                        }
                        a {
                            font-size: 13px;
                        }
                    }
                }
            }
            h3 {
                font-size: 17px;
                line-height: 1.5;
                margin: {
                    top: 20px;
                    bottom: 13px;
                };
            }
        }
        .article-footer {
            padding-top: 25px;
            text-align: center;

            .article-tags {
                flex: 0 0 100%;
                max-width: 100%;
            }
            .article-share {
                flex: 0 0 100%;
                max-width: 100%;

                .social {
                    text-align: center;
                    margin-top: 15px;
                }
            }
        }
        blockquote, .blockquote {
            padding: 20px !important;
    
            p {
                font-size: 17px !important;
            }
        }
    }
    .comments-area {
        .comments-title {
            font-size: 18px;
            margin-bottom: 25px;
        }
        .comment-author {
            .avatar {
                height: 50px;
                left: 0;
                position: relative;
                width: 50px;
                display: block;
                margin-bottom: 10px;
            }
        }
        .comment-body {
            padding-left: 0;
        }
        .comment-respond {
            .comment-reply-title {
                font-size: 18px;
            }
            .comment-form-author {
                width: 100%;
                padding-right: 0;
            }
            .comment-form-email {
                width: 100%;
                padding-left: 0;
            }
            .form-submit input {
                font-size: 14px;
                padding: 10px 30px 10px;
            }
            .comment-form-cookies-consent {
                label {
                    font-size: 15px;
                }
            }
        }
    }
    .comments-area .comment-respond input[type="date"], .comments-area .comment-respond input[type="time"], .comments-area .comment-respond input[type="datetime-local"], .comments-area .comment-respond input[type="week"], .comments-area .comment-respond input[type="month"], .comments-area .comment-respond input[type="text"], .comments-area .comment-respond input[type="email"], .comments-area .comment-respond input[type="url"], .comments-area .comment-respond input[type="password"], .comments-area .comment-respond input[type="search"], .comments-area .comment-respond input[type="tel"], .comments-area .comment-respond input[type="number"], .comments-area .comment-respond textarea {
        font-size: 14px;
    }
    .prev-link-wrapper {
        flex: 0 0 100%;
        max-width: 100%;
        padding-right: 0;

        .prev-title {
            font-size: 15px;
        }
        .meta-wrapper {
            font-size: 13px;
        }
    }
    .next-link-wrapper {
        flex: 0 0 100%;
        max-width: 100%;
        padding-left: 0;
        margin-top: 30px;

        .next-title {
            font-size: 15px;
        }
        .meta-wrapper {
            font-size: 13px;
        }
    }

    .subscribe-area {
        text-align: center;
        padding-bottom: 30px;
        margin-bottom: 30px;
        
        h2 {
            font-size: 20px;
            margin-bottom: 20px;
            line-height: 1.3;
        }
        .newsletter-form {
            padding-left: 0;

            .input-newsletter {
                height: 45px;
                font-size: 13px;
                padding-left: 10px;
            }
            button {
                position: relative;
                margin-top: 15px;
                font-size: 13px;
                border-radius: 5px;
                height: auto;
                padding: {
                    top: 11px;
                    bottom: 11px;
                };
            }
        }
    }

    .page-title-area {
        padding: {
            top: 60px;
            bottom: 50px;
        };
    }
    .page-title-content {
        text-align: center;

        h2 {
            font-size: 25px;
        }
        ul {
            margin-top: 10px;

            li {
                font-size: 14px;

                &::before {
                    right: -13px;
                    top: 6px;
                }
            }
        }
    }

    .appointment-form {
        max-width: 100%;
        padding: 20px;

        form {
            .form-group {
                label {
                    font-size: 12px;
                }
                .nice-select {
                    font-size: 13px;
                }
            }
            .default-btn {
                margin-top: 0;
                padding: {
                    top: 12px;
                    bottom: 12px;
                };
            }
        }
    }

    .faq-accordion-tab {
        .tabs {
            margin-bottom: 20px;

            li {
                margin: {
                    bottom: 20px;
                    left: 5px;
                    right: 5px;
                };
                a {
                    padding: 15px;
                    font-size: 14px;

                    i {
                        font-size: 25px;
                    }
                }
            }
        }
    }
    .faq-accordion-content {
        .accordion {
            .accordion-title {
                padding: 18px 20px 15px 47px;
                font-size: 14px;

                i {
                    left: 15px;
                    top: 16px;
                }
            }
            .accordion-content {
                padding: {
                    right: 20px;
                    left: 20px;
                    top: 5px;
                };
                p {
                    line-height: 1.8;
                }
            }
        }
    }

    .error-404-area {
        overflow: hidden;
        
        .notfound {
            .notfound-bg {
                div {
                    height: 100px;
                }
            }
            h1 {
                font-size: 50px;
            }
            h3 {
                font-size: 18px;
            }
        }
    }

    .coming-soon-area {
        height: 100%;
        padding: {
            top: 100px;
            bottom: 100px;
            left: 15px;
            right: 15px;
        };
    }
    .coming-soon-content {
        margin-left: 0;
        padding: 30px 25px;

        h2 {
            margin-top: 25px;
            font-size: 25px;
        }
        #timer {
            margin-top: 10px;

            div {
                width: 95px;
                height: 100px;
                font-size: 35px;
                margin-top: 20px;

                span {
                    font-size: 14px;
                }
            }
        }
        form {
            max-width: 100%;
            margin-top: 40px;

            .form-group {
                margin-bottom: 20px;

                .input-newsletter {
                    padding: 0 0 0 29px;
                    height: 40px;
                    font-size: 14px;
                }
                .label-title {
                    i {
                        top: 10px;
                        font-size: 19px;
                    }
                }
            }
        }
    }

    .cart-table {
        table {
            thead {
                tr {
                    th {
                        padding: 0 65px 15px 0;
                        font-size: 13px;
                    }
                }
            }
            tbody {
                tr {
                    td {
                        padding-right: 10px;

                        &.product-name {
                            a {
                                font-size: 14px;
                            }
                            ul {
                                margin-top: 8px;
                            }
                        }
                    }
                }
            }
        }
    }
    .cart-buttons {
        text-align: center;

        .text-end {
            text-align: center !important;
            margin-top: 15px;
        }
    }
    .cart-totals {
        padding: 20px;
        max-width: 100%;
        margin: {
            left: 0;
            top: 30px;
            right: 0;
        };
        h3 {
            font-size: 15px;
        }
        ul {
            margin: 0 0 20px;

            li {
                font-size: 13px;

                &:last-child {
                    font-size: 15px;
                }
            }
        }
    }

    .user-actions {
        padding: 15px;

        span {
            font-size: 14px;
        }
        i {
            margin-right: 0;
            font-size: 17px;
            top: 2px;
        }
    }
    .billing-details {
        .title {
            font-size: 15px;
        }
        .form-group {
            .nice-select {
                font-size: 14px;
                line-height: 45px;
            }
            label {
                font-size: 13px;
            }
        }
    }
    .order-details {
        margin-top: 30px;

        .title {
            font-size: 15px;
        }
        .order-table {
            table {
                thead {
                    tr {
                        th {
                            font-size: 14px;
                            white-space: nowrap;
                        }
                    }
                }
                tbody {
                    tr {
                        td {
                            font-size: 13px;
                            white-space: nowrap;
                        }
                    }
                }
            }
        }
        .payment-box {
            padding: 25px;
        }
    }
    .order-details .payment-box .payment-method p [type="radio"]:checked + label::before, .order-details .payment-box .payment-method p [type="radio"]:not(:checked) + label::before {
        top: 3px;
    }
    .order-details .payment-box .payment-method p [type="radio"]:checked + label::after, .order-details .payment-box .payment-method p [type="radio"]:not(:checked) + label::after {
        top: 6px;
    }

    .products-details-desc {
        margin-top: 30px;

        h3 {
            margin-bottom: 15px;
            font-size: 17px;
        }
        .price {
            font-size: 14px;
        }
        .products-info {
            li {
                font-size: 15px;
            }
        }
        .products-info-btn {
            margin-bottom: 18px;

            a {
                font-size: 13px;
                margin-bottom: 7px;

                i {
                    font-size: 17px;
                }
            }
        }
        .products-add-to-cart {
            .input-counter {
                input {
                    height: 40px;
                    font-size: 16px;
                }
                span {
                    width: 40px;
                    line-height: 44px;
                    font-size: 20px;
                }
            }
            .default-btn {
                position: relative;
                top: -2px;
            }
        }
        .products-details-accordion {
            .accordion-item {
                .accordion-header {
                    .accordion-button {
                        border-left-width: 2px;
                        font-size: 14px;
                    }
                }
                p {
                    font-size: 13.5px;
                }
                .table {
                    &.table-striped {
                        tbody {
                            tr {
                                td {
                                    font-size: 13.5px;
                                }
                            }
                        }
                    }
                }
                h3 {
                    font-size: 15px;
                }
                .products-review-form {
                    .review-title {
                        .default-btn {
                            position: relative;
                            margin-top: 15px;
                        }
                    }
                    .review-comments {
                        margin-top: 25px;

                        .review-item {
                            padding-right: 0;

                            h3 {
                                font-size: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
    .related-products {
        margin-top: 45px;
    }

    .pagination-area {
        margin-top: 10px;

        .page-numbers {
            font-size: 17px;

            &.prev, &.next {
                font-size: 25px;
            }
        }
    }

    .contact-info {
        h3 {
            margin-bottom: 13px;
            font-size: 16px;
        }
    }
    .contact-form {
        margin-bottom: 30px;

        h3 {
            margin-bottom: 13px;
            font-size: 16px;
        }
    }

    .footer-area {
        padding-top: 60px;
    }
    .single-footer-widget {
        h3 {
            font-size: 15px;
            margin-bottom: 20px;
        }
    }
    .footer-shape1, .footer-shape2, .footer-shape3 {
        display: none;
    }
    .footer-bottom-area {
        margin-top: 30px;
        text-align: center;
        padding: {
            top: 20px;
            bottom: 20px;
        };
        ul {
            text-align: center;
            margin-top: 10px;
        }
    }

    #live-map-tracker {
        .link-btn {
            left: 15px;
            transform: unset;
            top: 55px;
            font-size: 13px;
        }
    }

    .go-top {
        right: 10px;
        width: 40px;
        height: 40px;
        line-height: 41px;
        font-size: 25px;
    }

    .live-data-sidebar {
        top: 20%;
        z-index: 99;
        transform: translateY(-20%);

        .inner-content {
            opacity: 0;
            visibility: hidden;
            position: relative;
            right: -100%;
        }
        &.active {
            .inner-content {
                opacity: 1;
                visibility: visible;
                right: 0;
            }
        }
        .live-data-btn {
            opacity: 1;
            visibility: visible;
        }
    }

}

@media only #{$media} and ($feature_min : $value_one) and ($feature_max : $value_two) {

    .container {
        max-width: 540px;
    }

    .banner-section {
        .container-fluid {
            max-width: 540px;
        }
    }

    .symptoms-content {
        ul {
            li {
                flex: 0 0 50%;
                max-width: 50%;
            }
        }
    }
    .symptoms-section {
        .col-lg-4 {
            &:nth-child(1) {
                order: 1;
            }
            &:nth-child(2) {
                order: 3;
            }
            &:nth-child(3) {
                order: 2;
            }
        }
    }
    .symptoms-img {
        margin-bottom: 0;
    }

    .how-to-protect-area {
        .container-fluid {
            max-width: 540px;
        }
    }

    .funfacts-section {
        .container-fluid {
            max-width: 540px;
        }
    }

    .spread-virus-area {
        .container-fluid {
            max-width: 540px;
        }
    }
    .about-content-two {
        .features-list {
            text-align: start;

            li {
                flex: 0 0 50%;
                max-width: 50%;
                
                span {
                    padding-left: 33px;

                    i {
                        display: block;
                    }
                }
            }
        }
    }

}

@media only #{$media} and ($feature_min : $value_three) and ($feature_max : $value_four) {

    .container {
        max-width: 720px;
    }
    .default-btn {
        font-size: 13px;
    }
    .optional-btn {
        font-size: 13px;
    }
    .ptb-100 {
        padding: {
            top: 80px;
            bottom: 80px;
        };
    }
    .pt-100 {
        padding-top: 80px;
    }
    .pb-100 {
        padding-bottom: 80px;
    }
    .ptb-70 {
        padding: {
            top: 50px;
            bottom: 50px;
        };
    }
    .pt-70 {
        padding-top: 50px;
    }
    .pb-70 {
        padding-bottom: 50px;
    }
    .section-title {
        h2 {
            font-size: 30px;
        }
    }

    .header-area {
        position: relative;
        z-index: 999;
    }

    .qovid-nav {
        .navbar {
            .navbar-nav {
                .nav-item {
                    .dropdown-menu {
                        left: auto;
                        right: 0;
                    }
                }
            }
            .others-option {
                display: none !important;
            }
        }
    }
    .navbar-area {
        &.navbar-style-two {
            .container-fluid {
                max-width: 720px;
                padding: {
                    left: 15px;
                    right: 15px;
                };
            }
        }
        &.navbar-style-three {
            .container-fluid {
                max-width: 720px;
                padding: {
                    left: 15px;
                    right: 15px;
                };
            }
        }
    }

    .top-header {
        text-align: center;
    }
    .top-header-contact-info {
        text-align: center;
        margin-top: 5px;
    }

    .main-banner {
        padding-top: 130px;
    }
    .main-banner-content {
        margin-bottom: 30px;
        text-align: center;

        .sub-title {
            display: inline-block;
            font-size: 16px;
        }
        h1 {
            font-size: 40px;
        }
        p {
            max-width: 600px;
            margin: {
                left: auto;
                right: auto;
            };
        }
    }
    .banner-section {
        padding: {
            top: 200px;
        };
        .container-fluid {
            max-width: 720px;
        }
        .main-banner-content {
            margin-left: 0;
            max-width: 100%;
    
            h1 {
                font-size: 40px;
            }
            p {
                max-width: 100%;
            }
        }
        .main-banner-image {
            text-align: center;
        }
    }
    .hero-banner {
        padding-top: 140px;
    }
    .hero-banner-content {
        h1 {
            font-size: 35px;
        }
        .banner-box-list {
            margin-bottom: 15px;

            .single-banner-box {
                margin-bottom: 15px;

                h3 {
                    font-size: 30px;
                }
                p {
                    font-size: 15.5px;
                }
            }
        }
    }
    .banner-wrapper {
        height: auto;
        padding: {
            top: 200px;
            bottom: 120px;
        };
    }
    .banner-wrapper-content {
        max-width: 540px;
        text-align: center;
        margin: {
            left: auto;
            top: 0;
            right: auto;
        };
        h1 {
            font-size: 40px;
        }
        p {
            font-size: 15px;
        }
    }
    .banner-area {
        height: auto;
        padding: {
            top: 140px;
            bottom: 90px;
        };
    }
    .banner-image {
        text-align: center;
        height: auto;
        margin: {
            top: 40px;
            left: auto;
            right: auto;
        };
        img {
            position: relative;
            display: inline-block;

            &:nth-child(1) {
                left: 0;
                top: 0;
            }
            &:nth-child(2) {
                right: 0;
                top: 0;
            }
            &:nth-child(3) {
                left: 25px;
                bottom: 30px;
            }
        }
    }
    .home-slides-two {
        .main-banner {
            height: auto;
            padding: {
                top: 150px;
                bottom: 150px;
            };
        }
    }

    .about-content {
        padding-left: 0;
        margin-top: 30px;

        h2 {
            font-size: 30px;
        }
        blockquote {
            p {
                font-size: 16px;
            }
        }
    }
    .about-inner-area {
        margin-top: 35px;
        
        .image {
            order: 1;
        }
        .content {
            order: 2;
        }
    }
    .about-image-two {
        img {
            width: 100%;
        }
    }
    .about-content-two {
        text-align: center;
        margin-top: 30px;
        padding: {
            left: 0;
            right: 0 !important;
        };
        h2 {
            font-size: 30px;
        }
        .features-text {
            margin-top: 20px;

            h5 {
                padding-left: 0;
                margin-bottom: 12px;

                i {
                    display: none;
                }
            }
        }
        .features-list {
            text-align: start;
        }
    }

    #live-map-tracker {
        .link-btn {
            top: 10.5px;
            font-size: 14px;
        }
    }

    .how-to-spread-area {
        &.bg-white-color {
            padding-top: 20px;

            &::before {
                max-width: 720px;
            }
        }
    }
    .overview-box {
        margin-top: 35px;

        &:nth-child(2) {
            margin-top: 0;
        }
        .overview-content {
            flex: 0 0 100%;
            order: 2;
            max-width: 100%;

            .content {
                padding-left: 0;
                text-align: center;
                margin-top: 30px;
            }
        }
        .overview-image {
            flex: 0 0 100%;
            order: 1;
            max-width: 100%;

            .image {
                margin-right: 0;

                .main-image {
                    padding: 0;
                }
                .shape {
                    display: none;
                }
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    .spread-shape2, .spread-shape1 {
        display: none;
    }
    
    .funfacts-section {
        .funfacts-list {
            max-width: 100%;

            .row {
                .col-lg-6 {
                    &:nth-child(4) {
                        .single-funfacts-box {
                            margin-top: 30px;
                        }
                    }
                }
                .col-lg-12 {
                    margin-top: 0;
                }
            }
        }
    }

    .symptoms-section {
        &.bg-white-color {
            padding-top: 20px;

            &::before {
                max-width: 720px;
            }
        }
    }

    .cta-inner-area {
        margin-top: 0;
        padding-top: 60px;

        .row {
            .col-lg-8 {
                order: 2;
            }
            .col-lg-4 {
                order: 1;
            }
        }
        img {
            top: 0;
            margin-bottom: 0;
        }
        .cta-btn {
            margin-bottom: 30px;
        }
    }

    .symptoms-area {
        background-image: unset !important;

        .row {
            .col-lg-6 {
                &:nth-child(1) {
                    order: 2;
                }
                &:nth-child(2) {
                    order: 1;
                }
            }
        }
    }
    .symptoms-image {
        margin-top: 30px;
        left: 0;
        text-align: center;
    }
    .symptoms-content {
        text-align: center;
        padding-left: 0;

        h2 {
            font-size: 30px;
        }
        ul {
            text-align: start;

            li {
                span {
                    font-size: 15px;
                }
            }
        }
    }

    .symptoms-section {
        .col-lg-4 {
            &:nth-child(1) {
                order: 1;
            }
            &:nth-child(2) {
                order: 3;
            }
            &:nth-child(3) {
                order: 2;
            }
        }
    }
    .symptoms-box-list {
        .box-item {
            h3 {
                font-size: 19px;
            }
            p {
                font-size: 14px;
            }
            &:nth-child(1) {
                left: 0;
            }
            &:nth-child(3) {
                left: 0;
            }
        }
    }
    .row {
        .col-lg-4 {
            &:nth-child(3) {
                .symptoms-box-list {
                    .box-item {
                        &:nth-child(1) {
                            left: 0;
                        }
                        &:nth-child(3) {
                            left: 0;
                        }
                    }
                }
            }
        }
    }
    .symptoms-img {
        left: 0;

        img {
            max-width: 100%;
            margin-top: 30px;
        }
    }
    .symptoms-shape1, .symptoms-shape2 {
        display: none;
    }

    .about-section {
        .col-lg-6 {
            &:nth-child(1) {
                order: 2;
            }
            &:nth-child(2) {
                order: 1;
            }
        }
    }
    .about-img {
        margin-top: 30px;
    }

    .outbreak-content {
        .outbreak-box-list {
            margin-bottom: 15px;

            .single-outbreak-box {
                margin-bottom: 15px;

                h3 {
                    font-size: 30px;
                }
                p {
                    font-size: 15.5px;
                }
            }
        }
    }

    .funfacts-image {
        text-align: center;

        img {
            width: 100%;
        }
    }
    .funfacts-list {
        .single-funfacts-box {
            margin: {
                bottom: 0 !important;
                top: 30px;
            };
        }
    }
    .single-funfacts-box {
        h3 {
            font-size: 35px;
        }
    }
    .funfacts-section {
        .container-fluid {
            max-width: 720px;
        }
        .funfacts-image {
            max-width: 100%;
            margin-left: 0;
        }
    }

    .how-to-protect-content {
        .content {
            .text {
                h3 {
                    font-size: 17px;
                }
            }
        }
    }
    .how-to-protect-image {
        height: auto;
        margin: {
            left: 0;
            top: 30px;
        };
        .main-image {
            top: 0;
            transform: unset;
            border-width: 8px;
            right: 0;
            text-align: center;
            margin: {
                left: auto;
                right: auto;
            };
            &:nth-child(1) {
                position: relative;
            }
        }
        .how-to-protect-shape {
            display: none;
        }
    }
    .how-to-protect-area {
        .container-fluid {
            max-width: 720px;
        }
    }
    .how-to-protect-content-list {
        max-width: 580px;
        margin: {
            left: auto;
            right: auto;
        };
    }
    .how-to-protect-img {
        margin-top: 30px;
    }

    .faq-image {
        height: auto;
        background-image: unset !important;
        margin-bottom: 30px;

        img {
            display: inline-block;
        }
    }
    .faq-shape1, .faq-shape2 {
        display: none;
    }

    .emergency-contact-inner {
        text-align: center;

        .contact-cta {
            margin-top: 30px;
        }
    }

    .faq-section {
        .faq-accordion {
            margin-bottom: 15px;
        }
    }
    .faq-img {
        max-width: 620px;
        margin-top: 30px;
    }

    .about-shape1 {
        display: none;
    }

    .spread-virus-area {
        .container-fluid {
            max-width: 720px;
        }
    }
    .spread-virus-content {
        max-width: 600px;
        text-align: center;
        margin: {
            left: auto;
            right: auto;
            bottom: 0;
        };
        h2 {
            font-size: 30px;
        }
        ul {
            li {
                padding-left: 0;

                .icon {
                    position: relative;
                    left: 0;
                    top: 0;
                    display: inline-block;
                    margin-bottom: 15px;
                }
                h3 {
                    font-size: 19px;
                }
                p {
                    max-width: 70%;
                    margin: {
                        left: auto;
                        right: auto;
                    };
                }
            }
        }
    }
    .spread-virus-image {
        padding-top: 30px;
        left: 0;
    }
    .spread-shape3 {
        display: none;
    }

    .prevention-content {
        .title {
            h3 {
                font-size: 20px;
            }
        }
        .content-list {
            .content {
                padding-left: 0;

                .icon {
                    position: relative;
                    left: 0;
                    top: 0;
                    margin-bottom: 20px;
                }
                h3 {
                    font-size: 18px;
                }
            }
        }
    }
    .prevention-shape1, .prevention-shape2, .prevention-shape3, .prevention-shape4 {
        display: none;
    }

    .single-blog-post {
        .post-image {
            a {
                img {
                    width: 100%;
                }
            }
        }
        &::before {
            height: 50%;
        }
    }
    .small-blog-post {
        .single-small-blog-post {
            margin-bottom: 20px;

            .post-image {
                padding: 2px;
            }
            .post-content {
                h3 {
                    font-size: 15px;
                }
            }
        }
    }

    .appointment-form {
        padding: 35px;
        max-width: 720px;
    }

    .subscribe-area {
        text-align: center;

        .newsletter-form {
            padding-left: 0;
            margin-top: 30px;
        }
    }

    .faq-accordion-tab {
        .tabs {
            margin-bottom: 20px;

            li {
                margin-bottom: 20px;
            }
        }
    }

    .page-title-area {
        padding: {
            top: 120px;
            bottom: 100px;
        };
    }
    .page-title-content {
        h2 {
            font-size: 40px;
        }
    }

    .coming-soon-content {
        h2 {
            font-size: 35px;
        }
    }

    .order-details {
        margin-top: 40px;
    }

    .products-details-desc {
        margin-top: 35px;
    }

    .single-blog-post-item {
        .post-content {
            h3 {
                font-size: 17px;
            }
        }
    }

    .widget-area {
        margin-top: 35px;
    }

    .contact-info {
        margin-top: 35px;
    }

    .footer-area {
        padding-top: 80px;
    }
    .footer-shape1, .footer-shape2, .footer-shape3 {
        display: none;
    }
    .footer-bottom-area {
        margin-top: 50px;
    }

    .live-data-sidebar {
        top: 20%;
        z-index: 99;
        transform: translateY(-20%);

        .inner-content {
            opacity: 0;
            visibility: hidden;
            position: relative;
            right: -100%;
        }
        &.active {
            .inner-content {
                opacity: 1;
                visibility: visible;
                right: 0;
            }
        }
        .live-data-btn {
            opacity: 1;
            visibility: visible;
        }
    }

}

@media only #{$media} and ($feature_min : $value_five) and ($feature_max : $value_six) {

    .container {
        max-width: 960px;
    }

    .navbar-area {
        &.navbar-style-two {
            .container-fluid {
                padding: {
                    left: 25px;
                    right: 25px;
                };
            }
            .qovid-nav {
                .navbar {
                    .navbar-nav {
                        .nav-item {
                            a {
                                margin: {
                                    left: 10px;
                                    right: 10px;
                                };
                            }
                            &:first-child {
                                a {
                                    margin: {
                                        left: 0;
                                    };
                                }
                            }
                            &:last-child {
                                a {
                                    margin: {
                                        right: 0;
                                    };
                                }
                            }
                        }
                    }
                }
            }
        }
        &.navbar-style-three {
            .container-fluid {
                padding: {
                    left: 25px;
                    right: 25px;
                };
            }
        }
    }
    .qovid-nav {
        .navbar {
            .others-option {
                .option-item {
                    &:nth-child(3) {
                        display: none;
                    }
                }
                &::before {
                    top: 2px;
                }
            }
        }
    }

    .main-banner-content {
        h1 {
            font-size: 50px;
        }
    }
    .hero-banner-content {
        h1 {
            font-size: 45px;
        }
        .banner-box-list {
            padding: {
                left: 0;
                right: 0;
            };
            .single-banner-box {
                padding: {
                    right: 15px;
                    left: 75px;
                };
                .icon {
                    left: 15px;

                    img {
                        width: 45px;
                    }
                }
                h3 {
                    font-size: 30px;
                }
                p {
                    font-size: 14px;
                }
            }
        }
    }
    .banner-wrapper-content {
        h1 {
            font-size: 45px;
        }
    }
    .banner-area {
        .main-banner-content {
            h1 {
                font-size: 49px;
            }
        }
    }
    .banner-image {
        img {
            &:nth-child(1) {
                width: 250px;
                left: 0;
            }
            &:nth-child(2) {
                width: 200px;
            }
            &:nth-child(3) {
                width: 200px;
                bottom: -50px;
            }
        }
    }

    .cta-inner-area {
        .cta-btn {
            a {
                padding: 15px 30px 15px 30px;

                i {
                    display: none;
                }
            }
        }
    }
    .faq-shape1, .faq-shape2 {
        display: none;
    }

    .symptoms-section {
        &.bg-white-color {
            &::before {
                max-width: 960px;
            }
        }
    }

    .about-section {
        .col-lg-6 {
            flex: 0 0 100%;
            max-width: 100%;
            
            &:nth-child(1) {
                order: 2;
            }
            &:nth-child(2) {
                order: 1;
            }
        }
    }
    .about-img {
        margin-top: 30px;
    }
    .about-shape1 {
        img {
            max-width: 78%;
        }
    }
    .about-content-two {
        .features-list {
            li {
                span {
                    font-size: 15px;
                }
            }
        }
    }

    .spread-virus-area {
        .container-fluid {
            max-width: 960px;

            .col-lg-6 {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
    }
    .spread-virus-content {
        max-width: 720px;
        margin: {
            left: auto;
            bottom: 0;
            right: auto;
        };
        ul li p {
            max-width: 100%;
        }
    }
    .spread-virus-image {
        left: 0;
        padding-top: 30px;
    }
    .spread-shape3 {
        display: none;
    }

    .symptoms-area {
        background-position: -340px center;
    }
    .symptoms-image {
        left: 0;
    }
    .symptoms-content {
        background-color: #f1f5fe;

        ul {
            li {
                span {
                    padding-left: 15px;
                    font-size: 16px;

                    i {
                        display: none;
                    }
                }
            }
        }
    }
    .symptoms-shape1, .symptoms-shape2 {
        display: none;
    }

    .single-funfacts-box {
        h3 {
            font-size: 35px;
        }
    }

    .how-to-protect-content {
        .content {
            .text {
                padding: 20px 20px 20px 115px;

                .icon {
                    left: 20px;
                }
                h3 {
                    font-size: 17px;
                }
            }
        }
    }

    .how-to-spread-area {
        &.bg-white-color {
            &::before {
                max-width: 960px;
            }
        }
    }

    .funfacts-section {
        .funfacts-list {
            max-width: 485px;
        }
    }

    .emergency-contact-inner {
        .contact-cta {
            a {
                padding: 15px 30px 15px 30px;

                i {
                    display: none;
                }
            }
        }
    }

    .products-area {
        .row {
            .col-lg-3 {
                flex: 0 0 33.333333%;
                max-width: 33.333333%;
            }
        }
    }

    .overview-box {
        margin-bottom: 30px;

        .overview-image {
            .image {
                .main-image {
                    padding: 20px;
                }
                .shape {
                    img {
                        &:nth-child(1) {
                            top: 0;
                        }
                    }
                }
            }
        }
        &:nth-child(2) {
            margin-top: 60px;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    .how-to-spread-area {
        .container {
            max-width: 885px;
        }
    }

    .subscribe-area {
        h2 {
            font-size: 22px;
        }
    }

    .banner-section {
        .main-banner-content {
            max-width: 430px;
            margin-left: auto;
        }
    }

    .spread-shape1, .spread-shape2 {
        display: none;
    }

    .funfacts-section {
        .funfacts-list {
            .row .col-lg-6:nth-child(1) .single-funfacts-box {
                margin-top: 0;
            }
        }
        .funfacts-image {
            max-width: 480px;
        }
    }

    .how-to-protect-content-list {
        max-width: 395px;
    }

    .funfacts-section {
        .funfacts-list {
            .row {
                .col-lg-6 {
                    &:nth-child(4) {
                        .single-funfacts-box {
                            margin-top: 0;
                        }
                    }
                }
                .col-lg-12 {
                    margin-top: 0;
                }
            }
        }
    }

    .footer-shape1, .footer-shape2, .footer-shape3 {
        display: none;
    }

    .small-blog-post {
        .single-small-blog-post {
            .post-image {
                a {
                    img {
                        max-width: 90px;
                    }
                }
            }
            .post-content {
                h3 {
                    font-size: 17px;
                }
            }
        }
    }

    .outbreak-content {
        .outbreak-box-list {
            padding: {
                left: 0;
                right: 0;
            };
            .single-outbreak-box {
                padding: {
                    right: 15px;
                    left: 75px;
                };
                .icon {
                    left: 15px;

                    img {
                        width: 45px;
                    }
                }
                h3 {
                    font-size: 30px;
                }
                p {
                    font-size: 14px;
                }
            }
        }
    }

}

@media only #{$media} and ($feature_min : $value_seven) {

    .banner-section {
        .main-banner-content {
            max-width: 750px;
        }
    }

    .how-to-protect-content-list {
        max-width: 425px;
    }

    .symptoms-area {
        background-position: -80px center;
    }

    .spread-virus-image {
        padding-top: 120px;
        left: -15px;
    }
    .spread-shape3 {
        img {
            max-width: 100%;
        }
    }
    .prevention-shape2 {
        display: block;
    }
    .prevention-shape4 {
        display: block;
    }

    .symptoms-shape1 {
        right: 3%;
    }
    .symptoms-shape2 {
        left: 1%;
    }

}